import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Form } from "react-bootstrap";
import Button from "../../../../src/shared/components/Buttons/Button";
import { transactionActions } from "../../../redux/actions";
import SearchField from "../../../../src/shared/components/searchField/SearchField";
import CustomTooltip from "../../../../src/shared/components/toolTipComponent/CustomTooltip";
import Topbar from "../../../../src/shared/components/topbar/Topbar";
import "react-datepicker/dist/react-datepicker.css";
import {
  buttonNameConstants,
  buttonTypeConstants,
  categoryDropdownConstants,
  inventoryConstants,
  globalConstants,
  transactionConstants,
} from "../../../constants";
import {
  SearchIcon,
  BlueBoxIcon,
  ActionDotsIcons,
  ExportCSVIcon,
  ShowIcon,
  CrossIcon,
} from "../../../assets/icons/iconsProvider";
import DataTableComponent from "../../../../src/shared/components/table/ReactDataTableComponent";
import TransactionOverViewCard from "../../../shared/components/transactionOverViewCard/TransactionOverViewCard";
import CategoriesDropdown from "../../../shared/components/selectDropdown/CategoriesDropdown";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import {
  calcullateItems,
  downloadCSV,
  formatDate,
  getUnitPurchasePrice,
  getShortestSku,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
  parseToThousandSeperatorWholeNumber,
  getReceiptPrintDate,
  formatTime,
} from "../../../shared/utility";
import TableCustomHeader from "../../../shared/components/table/TableCustomHeader";
import moment from "moment";
import { PRODUCT_TYPES_ENUMS } from "../../../system/globalEnums";
import PrintLabelModal from "../../Inventory/Items/printingLabelModal/PrintLabelModal";
import ImportTransactionHistory from "./ImportTransactionHistory/ImportTransactionHistory";
import { prepareReceiptForPrinter } from "../../../shared/Printers/ReceiptPrinters/prepareReceiptForPrinter";
import { printReceiptData } from "../../../shared/Printers/ReceiptPrinters/printReceipt";
import { prepareReceiptForPrinterTrade } from "../../../shared/Printers/ReceiptPrinters/prepareReceiptForPrinterTrade";
import DatetimePickerDropdown from "../../../shared/components/datetimePickerDropdown/DatetimePickerDropdown";
import SummaryCardSkeleton from "../../../shared/Skeletons/inventorySkeletons/SummaryCardSkeleton";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

//-------History component start here
const History = (props) => {
  const {
    currentStore,
    transaction,
    getAllTransaction,
    bulkUploadTransactionHistory,
    emptyDuplicateRecords,
    printer,
    user,
    spinnerArray,
  } = props;
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [gridState, setGridState] = useState(defaultGridState);
  let numOfRenders = useRef(0);
  const { defaultReceiptPrinter } = printer;
  //-------History reducer
  const { allTransaction, duplicateHistoryRecords } = transaction;
  const allTransactionsArr = allTransaction.sellTradeHistory;
  const { sellTradeStats } = allTransaction;
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const [viewCartDetail, setViewCartDetail] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [printLabelModal, setPrintLabelModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [printRecieptData, setprintRecieptData] = useState([]);
  const [importTransactionHistoryModal, setImportTransactionHistoryModal] =
    useState(false);
  const [idType, setIdType] = useState("");
  const [option, setOption] = useState({
    label: globalConstants.ALL,
    value: globalConstants.ALL,
  });
  const [timeFrame, setTimeFrame] = useState({
    start: "",
    end: "",
    duration: "daily",
  });
  //-------Handle History edit click
  const handleViewCart = (row) => {
    const idPrefix = row.id.substring(0, 3);
    setIdType(idPrefix);
    setprintRecieptData(row);
    setViewCartDetail([row]);
    setSelectedRows([]);
    setSelectedRows(
      row?.inventory.map((item) => {
        const newObject = {
          sku: getShortestSku(item.sku),
          product_name: item.product_name,
          price: {
            quantity: item?.price?.quantity,
            type: item?.price?.type,
            unit_sell_price: item?.price?.unit_purchase_price,
          },
          category_name: item?.category_name || item?.category,
        };
        return newObject;
      })
    );
    toggleCustomTradeModal();
  };

  const toggleImportTransactionHistoryModal = () => {
    setImportTransactionHistoryModal(!importTransactionHistoryModal);
  };

  const transactionHeader = [
    {
      id: "saleId",
      name: "Sale ID",
      selector: (row) => row.id,
      cell: (row) => <span className="users-cell-max-size">{row.id}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "createdon",
      name: "Date",
      selector: (row) => formatDate(row.createdOn),
      cell: (row) => (
        <span className="users-cell-max-size">{formatDate(row.createdOn)}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "createdon",
      name: "Time",
      selector: (row) => formatTime(row.createdOn),
      cell: (row) => (
        <span className="users-cell-max-size">{formatTime(row.createdOn)}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "transactionType",
      name: (
        <TableCustomHeader
          id="transactionType"
          label="Transactio..."
          tooltopText="Transaction Type"
        />
      ),
      selector: (row) => row.type,
      cell: (row) => <span className="users-cell-max-size">{row.type}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "paymentType",
      name: "Payment",
      selector: (row) => row.PaymentType,
      cell: (row) => (
        <span className="users-cell-max-size">
          {" "}
          {row.PaymentType === transactionConstants.CREDIT_CARD
            ? buttonNameConstants.CREDIT_CARD
            : row.PaymentType === transactionConstants.GIFT_CARD
            ? buttonNameConstants.GIFT_CARD_PAYMENT
            : row.PaymentType === transactionConstants.STORE_CREDIT
            ? buttonNameConstants.STORE_CREDIT
            : buttonNameConstants.CASH}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "cartQuantity",
      name: "Cart QTY",
      selector: (row) =>
        row.inventory?.reduce((a, b) => a + Number(b.quantity), 0) ||
        row.inventory?.reduce((a, b) => a + Number(b.price.quantity), 0) ||
        0,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "customer",
      name: "Customer",
      selector: (row) => row.customer.name,
      cell: (row) => (
        <span className="users-cell-max-size">
          {row?.customer?.name ?? globalConstants.EMPTY_STRING}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "amount",
      name: "Amount",
      selector: (row) => row.totalAmountToPay,
      cell: (row) => (
        <span className="users-cell-max-size">
          ${parseToThousandSeperatorDecimalNumber(row.totalAmountToPay)}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                label={buttonNameConstants.VIEW_DETAILS}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={ShowIcon}
                handleClick={() => handleViewCart(row)}
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
    },
  ];

  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };
  //-------Handle searchfield change
  const handleFilterData = (e) => {
    const filterList = allTransactionsArr.filter((row) => {
      return (
        String(
          row.type +
            row.id +
            row.PaymentType +
            row.TotalAmountPaid +
            row.customer.name +
            formatDate(row.createdOn)
        )
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset the pagination
    numOfRenders.current++;
    return filterList;
  };
  const filterColumnforExport = (allData) => {
    return allData.map((row) => {
      const cartQuantity =
        row.inventory?.reduce(
          (acc, curr) =>
            acc + Number(curr.quantity || curr.price?.quantity || 0),
          0
        ) || 0;
      const exportObject = {
        SaleId: row.id,
        CreatedOn: row.createdOn,
        TransactionType: row.type,
        PaymentType: row.PaymentType,
        CartQuantity: cartQuantity,
        Customer: row.customer.name,
        TotalAmountPaid: row.totalAmountToPay,
      };
      return exportObject;
    });
  };
  const handleExportButtonClick = () => {
    downloadCSV(
      searchText
        ? filterColumnforExport(handleFilterData())
        : filterColumnforExport(allTransactionsArr)
    );
  };
  const toggleCustomTradeModal = () => {
    setShow(!show);
  };
  const handlePrintReceiptTrade = (newTradeData) => {
    const partialPayment =
      (newTradeData?.paymentHistory?.[transactionConstants.CASH] || 0) +
        (newTradeData?.paymentHistory?.[transactionConstants.STORE_CREDIT] ||
          0) >
        0 &&
      newTradeData?.totalAmountToPay !== newTradeData?.currentAmountToPay;
    const printData = prepareReceiptForPrinterTrade(
      user,
      newTradeData?.id,
      newTradeData?.paymentHistory,
      currentStore,
      newTradeData?.customer,
      [
        ...newTradeData?.inventory.map((inv) => {
          return {
            ...inv,
            cogs: inv?.price?.unit_purchase_price,
            item: inv?.product_name,
            priceAfterDiscount: partialPayment
              ? getUnitPurchasePrice(newTradeData, inv)
              : inv?.price?.unit_purchase_price,
            itemName: inv?.product_name,
            quantity: inv?.price?.quantity,
            productType: inv?.productType,
            category: inv?.category_name,

            // max: 2,
            // actualPrice: inv?.price?.unit_sell_price,
            // isDiscountApplied: false,
            // discountPercentage: 0,
            // priceAfterDiscount: 1000,
            // actualDiscountPercentage: 0,
          };
        }),
      ],
      newTradeData?.discount,
      newTradeData?.SubTotal,
      newTradeData?.Tax,
      newTradeData?.totalAmountToPay,
      0,
      0,
      false,
      partialPayment,
      false,
      getReceiptPrintDate(newTradeData.createdOn)
    );
    printReceiptData(printData, defaultReceiptPrinter);
  };
  const handlePrintReceipt = (newTradeData) => {
    if (idType == globalConstants.TYPE_ID_TRD) {
      handlePrintReceiptTrade(newTradeData);
    } else {
      const printData = prepareReceiptForPrinter(
        user,
        newTradeData.id,

        newTradeData.paymentHistory,
        currentStore,
        newTradeData.customer,
        [
          ...newTradeData?.inventory?.map((inv) => {
            return {
              ...inv,
              cogs: inv?.price?.unit_purchase_price,
              item: inv?.product_name,
              priceAfterDiscount: inv?.price?.unit_purchase_price,
              itemName: inv?.product_name,
              quantity: inv?.price?.quantity,
              productType: inv?.productType,
              category: inv?.category_name,
              // max: 2,
              // actualPrice: inv?.price?.unit_sell_price,
              // isDiscountApplied: false,
              // discountPercentage: 0,
              // priceAfterDiscount: 1000,
              // actualDiscountPercentage: 0,
            };
          }),
        ],
        newTradeData.discount,
        newTradeData.SubTotal,
        newTradeData.Tax,
        newTradeData.totalAmountToPay,
        newTradeData.cashTenderedAmount,
        newTradeData.cashChangeOwed,
        newTradeData.giftCardPaymentHistory,
        false,
        false,
        newTradeData.discountMethod,
        newTradeData.discountedValue,
        getReceiptPrintDate(newTradeData.createdOn)
      );
      printReceiptData(printData, defaultReceiptPrinter);
    }
  };
  const handlePrintProductLabel = () => {
    toggleCustomTradeModal();
    togglePrintLabelModal();
  };
  //-------Toggle Print Label Modal
  const togglePrintLabelModal = () => {
    setPrintLabelModal(!printLabelModal);
  };
  const getTimeFrame = (value) => {
    setTimeFrame({
      ...timeFrame,
      start: moment.utc(value.start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end: moment.utc(value.end).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      duration: value?.duration,
    });
  };
  const clearSearchText = () => {
    setSearchText("");
  };

  useEffect(() => {
    if (currentStore && timeFrame.start && timeFrame.end) {
      // getAllTransaction(currentStore?.id, value, start, end, cat);
      getAllTransaction(
        currentStore?.id,
        option?.value,
        timeFrame.start,
        timeFrame.end
        // cat
      );
      setColumns(transactionHeader);
    }
  }, [timeFrame.start, timeFrame.end, currentStore, option]);

  //-------Return History Component
  return (
    <>
      <Topbar
        handleClick={() => handleExportButtonClick()}
        exportButton={true}
        IconImage={ExportCSVIcon}
        label={buttonNameConstants.EXPORT_CSV}
        title="Transaction History"
        importCsvButton={true}
        handleImportCsvClick={() => toggleImportTransactionHistoryModal()}
      ></Topbar>
      <Row className="m-0 ">
        <Col
          md={12}
          className="transaction-overview-card-wrapper d-flex flex-wrap gap-3"
        >
          {!isSpinnerEnabled(
            spinnerArray,
            transactionConstants.GET_ALL_TRANSACTION
          ) ? (
            <>
              <TransactionOverViewCard
                label="Total Sold"
                data={`$${parseToThousandSeperatorDecimalNumber(
                  sellTradeStats?.totalSales ?? 0
                )}`}
              />

              <TransactionOverViewCard
                label="Total COGS"
                data={`$${parseToThousandSeperatorDecimalNumber(
                  sellTradeStats?.totalCogs ?? 0
                )}`}
              />

              <TransactionOverViewCard
                label="Total Net"
                data={`$${parseToThousandSeperatorDecimalNumber(
                  sellTradeStats?.totalNet ?? 0
                )}`}
              />

              <TransactionOverViewCard
                label="Total Number of Transactions"
                data={parseToThousandSeperatorWholeNumber(
                  sellTradeStats?.totalNumberOfTransactions
                )}
              />
            </>
          ) : (
            <>
              <SummaryCardSkeleton />
              <SummaryCardSkeleton />
              <SummaryCardSkeleton />
              <SummaryCardSkeleton />
            </>
          )}
        </Col>
        <Col md={12} className="d-flex align-items-center mb-2 gap-3">
          {isSpinnerEnabled(
            spinnerArray,
            transactionConstants.GET_ALL_TRANSACTION
          ) && (
            <>
              <DropdownSkeleton width="30vw" />
              <DropdownSkeleton />
              <DropdownSkeleton width="40vw" />
            </>
          )}

          <div
            className={`w-100 d-flex align-items-center gap-3 ${
              isSpinnerEnabled(
                spinnerArray,
                transactionConstants.GET_ALL_TRANSACTION
              )
                ? "opacity-0 hide-filter-dropdowns "
                : "opacity-100"
            }  `}
          >
            <DatetimePickerDropdown getTimeFrame={getTimeFrame} />{" "}
            <CategoriesDropdown
              options={inventoryConstants.TRANSACTION_TYPE}
              handleChange={(e) => {
                setOption(
                  e == null
                    ? {
                        label: globalConstants.ALL,
                        value: globalConstants.ALL,
                      }
                    : e
                );
              }}
              noOptionsMessage="No Transaction Type Found"
              placeholder="Transaction Type"
              SelectIconImage={categoryDropdownConstants.TRANSACTION_ICON}
              className={"dropdown-max-width"}
              isClearable={false}
              defaultValue={{
                value: PRODUCT_TYPES_ENUMS.ALL,
                label: "All",
              }}
            />{" "}
            <SearchField
              onChange={(e) => setSearchText(e.target.value)}
              IconImage={SearchIcon}
              BlueBoxIcon={BlueBoxIcon}
              placeHolder="Search"
              value={searchText}
              CrossIcon={searchText ? CrossIcon : ""}
              clearSearchText={clearSearchText}
            />
          </div>
        </Col>
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3">
        <DataTableComponent
          className="transaction-table-height"
          key={numOfRenders.current}
          columns={columns}
          data={searchText ? handleFilterData() : allTransactionsArr}
          onSort={onTableSort}
          defaultSortFieldId={defaultSortFieldId}
          gridState={gridState}
          setGridState={updateGridState}
          total={
            searchText ? handleFilterData()?.length : allTransactionsArr?.length
          }
        />
      </Row>
      <ImportTransactionHistory
        currentStore={currentStore}
        emptyDuplicateRecords={emptyDuplicateRecords}
        duplicateHistoryRecords={duplicateHistoryRecords}
        bulkUploadTransactionHistory={bulkUploadTransactionHistory}
        importTransactionHistoryModal={importTransactionHistoryModal}
        toggleImportTransactionHistoryModal={
          toggleImportTransactionHistoryModal
        }
      />
      <Modal
        show={show}
        onHide={toggleCustomTradeModal}
        size="md"
        animation={true}
        centered
        backdrop="static"
        className="add-inventory-modal"
      >
        <Form className="px-0">
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              <span className="modal-sale-transaction-id">
                {viewCartDetail?.[0]?.id.substring(0, 3) ===
                globalConstants.TYPE_ID_TRD
                  ? "Trade "
                  : viewCartDetail?.[0]?.id.substring(0, 3) ===
                    globalConstants.TYPE_RETURN
                  ? "Return "
                  : "Sale "}
                ({viewCartDetail?.[0]?.id})
              </span>
              <span className="tran-modal-items">
                {calcullateItems(viewCartDetail?.[0]?.inventory)} items
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            {idType == globalConstants.TYPE_ID_TRD &&
            (viewCartDetail?.[0]?.paymentHistory?.[transactionConstants.CASH] ||
              0) +
              (viewCartDetail?.[0]?.paymentHistory?.[
                transactionConstants.STORE_CREDIT
              ] || 0) >
              0 &&
            viewCartDetail?.[0]?.totalAmountToPay !==
              viewCartDetail?.[0]?.currentAmountToPay ? (
              <Row className="m-0 p-0 ">
                <div className="container-scrollbar p-0 pe-0  trans-modal-card-wrapper border-bottom mb-4">
                  {Array.isArray(viewCartDetail) &&
                    viewCartDetail[0]?.inventory?.map((product) => {
                      return (
                        <Col
                          md={12}
                          className="m-0 p-0 mb-4 d-flex justify-content-between"
                        >
                          <div className="d-flex align-items-center gap-1">
                            <span className="trans-items-modal">
                              {" "}
                              {product?.price?.quantity}
                            </span>
                            <span className="trans-product-name-modal">
                              {product.product_name}
                            </span>
                            <span className="trans-items-modal">
                              {" "}
                              {product?.sku !==
                                transactionConstants.QUICK_TRADE &&
                                (product?.sku?.length > 10
                                  ? ` #${product.sku.substring(0, 10)}...`
                                  : `#${product.sku}`)}
                            </span>
                          </div>
                          <div>
                            <span className="trans-product-name-modal-total">
                              $
                              {parseToThousandSeperatorDecimalNumber(
                                getUnitPurchasePrice(viewCartDetail[0], product)
                              )}
                            </span>
                          </div>
                        </Col>
                      );
                    })}
                </div>

                <Col md={12} className="m-0   p-0">
                  <span className="trans-product-name-modal-total">
                    Payment
                  </span>

                  {viewCartDetail[0] &&
                    viewCartDetail[0]?.paymentHistory[
                      transactionConstants.CASH
                    ] > 0 && (
                      <div className="d-flex w-100 justify-content-between align-items-center mt-1  border-top tran-modal-total-amount-div">
                        <div className="d-flex align-items-center gap-2">
                          <span className="subtotal-heading">Cash</span>
                        </div>
                        <span className="payment-cart-productname">
                          $
                          {parseToThousandSeperatorDecimalNumber(
                            viewCartDetail[0]?.paymentHistory[
                              transactionConstants.CASH
                            ]
                          )}
                        </span>
                      </div>
                    )}
                  {viewCartDetail[0] &&
                    viewCartDetail[0]?.paymentHistory[
                      transactionConstants.STORE_CREDIT
                    ] > 0 && (
                      <div className="d-flex w-100 justify-content-between align-items-center mt-3  border-top tran-modal-total-amount-div">
                        <div className="d-flex align-items-center gap-2">
                          <span className="subtotal-heading">Store Credit</span>
                        </div>
                        <span className="payment-cart-productname">
                          $
                          {parseToThousandSeperatorDecimalNumber(
                            viewCartDetail[0]?.paymentHistory[
                              transactionConstants.STORE_CREDIT
                            ]
                          )}
                        </span>
                      </div>
                    )}
                  {
                    <div className="tran-modal-total-amount-div mt-3">
                      <p className="trans-items-modal mb-2">Total</p>
                      <span className="trans-items-modal-total-text">
                        {" "}
                        ${" "}
                        {parseToThousandSeperatorDecimalNumber(
                          viewCartDetail[0]?.totalAmountToPay
                        )}
                      </span>
                    </div>
                  }
                </Col>
              </Row>
            ) : (
              <Row className="m-0 p-0 ">
                <div className="container-scrollbar p-0  trans-modal-card-wrapper border-bottom mb-4">
                  {Array.isArray(viewCartDetail) &&
                    viewCartDetail[0]?.inventory?.map((product) => {
                      return (
                        <Col
                          md={12}
                          className="m-0 p-0 mb-4 d-flex justify-content-between"
                        >
                          <div className="d-flex align-items-center gap-1">
                            <span className="trans-items-modal">
                              {product?.price?.quantity}
                            </span>
                            <span className="trans-product-name-modal">
                              {product.product_name}
                            </span>
                            <span className="trans-items-modal">
                              {product?.sku !==
                                transactionConstants.QUICK_TRADE &&
                                `#${getShortestSku(product?.sku)}`}
                            </span>
                          </div>
                          <div>
                            <span className="trans-product-name-modal">
                              $
                              {parseToThousandSeperatorDecimalNumber(
                                product?.price?.unit_purchase_price
                              )}
                            </span>
                          </div>
                        </Col>
                      );
                    })}
                </div>
                <Col
                  md={12}
                  className="m-0  mb-4 p-0 d-flex justify-content-between tran-modal-detail-margin"
                >
                  <div className="trans-product-name-modal-text m-0">
                    Subtotal
                  </div>

                  <div className="trans-product-name-modal-total">
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      viewCartDetail[0]?.SubTotal
                    )}
                  </div>
                </Col>
                <Col
                  md={12}
                  className="m-0  mb-4 p-0 d-flex justify-content-between tran-modal-detail-margin"
                >
                  <div className="trans-product-name-modal-text m-0">Tax</div>

                  <div className="trans-product-name-modal-total">
                    ${" "}
                    {parseToThousandSeperatorDecimalNumber(
                      viewCartDetail[0]?.Tax
                    )}
                  </div>
                </Col>
                <Col
                  md={12}
                  className="m-0  mb-4 p-0 d-flex justify-content-between tran-modal-detail-margin"
                >
                  <div className="trans-product-name-modal-text m-0">
                    Discount
                  </div>

                  <div className="trans-product-name-modal-total">
                    ${" "}
                    {parseToThousandSeperatorDecimalNumber(
                      viewCartDetail[0]?.discount
                    )}
                  </div>
                </Col>
                <Col md={12} className="m-0   p-0">
                  <div className="tran-modal-total-amount-div">
                    <p className="trans-items-modal mb-2">Total</p>
                    <span className="trans-items-modal-total-text">
                      ${" "}
                      {parseToThousandSeperatorDecimalNumber(
                        viewCartDetail[0]?.totalAmountToPay
                      )}
                    </span>
                  </div>
                </Col>
              </Row>
            )}
            {viewCartDetail[0]?.note && (
              <Col md={12} className="m-0 mt-3   p-0">
                <div className="note-text-div container-scrollbar">
                  <p className="trans-items-modal mb-2">Note</p>
                  <span className="note-text">{viewCartDetail[0]?.note}</span>
                </div>
              </Col>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              label={buttonNameConstants.PRINT_RECEIPT}
              handleClick={() => handlePrintReceipt(printRecieptData)}
              buttonType={buttonTypeConstants.LIGHT_PURPLE}
              className="w-100"
            ></Button>
            <Button
              type="button"
              className="w-100"
              label={buttonNameConstants.PRINT_PRODUCT_LABEL}
              handleClick={handlePrintProductLabel}
            ></Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <PrintLabelModal
        printLabelModal={printLabelModal}
        togglePrintLabelModal={togglePrintLabelModal}
        selectedRows={selectedRows}
        // singlePrintArray={singlePrintArray}
      />
    </>
  );
}; //-------History component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  transaction: state.transaction,
  currentStore: state.store.currentStore,
  inventory: state.inventory,
  user: state.authentication.user,
  printer: state.printer,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getAllTransaction: (storeId, type, start, end, cat) =>
    dispatch(
      transactionActions.getAllTransaction(storeId, type, start, end, cat)
    ),
  bulkUploadTransactionHistory: (storeId, data) =>
    dispatch(transactionActions.bulkUploadTransactionHistory(storeId, data)),
  emptyDuplicateRecords: () =>
    dispatch(transactionActions.emptyDuplicateRecords()),
});

//-------Export History Component
export default connect(mapStateToProps, mapDispatchToProps)(History);
