import { connect } from "react-redux";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";

import {
  inventoryActions,
  systemActions,
  tradeInDiscountActions,
} from "../../../../redux/actions";
import SearchInventory from "../SearchInventory/SearchInventory";
import Button from "../../../../shared/components/Buttons/Button";
import {
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";
import ProductImage from "../../../../assets/images/productimage.png";
import {
  customToast,
  convertToUsd,
  stringToSlug,
  handleViewTCGPlayer,
  handleViewPriceCharting,
  handleViewEbaySales,
  getPricechartingProductImage,
  uploadCustomProductImage,
  convertPricesToNumbers,
  changePriceBaseOnTags,
  getShortestSku,
  handleConditionsAndTags,
  handleShowNintendoCondition,
  getPresetStockPrice,
  handleConditionChange,
  handleTagList,
  getMarginDropdownValue,
  calculateOfferPrices,
  parseToDecimalNumber,
} from "../../../../shared/utility";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import CheckboxField from "../../../../shared/components/checkBox/CheckboxField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import {
  globalConstants,
  inventoryConstants,
  toastMessages,
  categoryDropdownConstants,
  toastType,
  buttonNameConstants,
  buttonTypeConstants,
  EMPTY_STRING,
} from "../../../../constants";
import {
  DollarIcon,
  SidebarToggleIcon,
  LogoImageReplaceIcon,
} from "../../../../assets/icons/iconsProvider";
import { inventoryService, storeService } from "../../../../services";
import ProductTagList from "../../../../shared/components/tags/ProductTagList";
import { addNewIventoryValidationSchema } from "../../../../shared/validationSchema/validationSchema";

const AddBatchInventoryEdit = (props) => {
  //-------Destructure props
  const {
    addInventoryItemModal,
    toggleAddInventoryItemModal,
    inventory,
    emptyPriceChartingProductObject,
    getInventoryByPricechartingId,
    currentStore,
    activateSpinner,
    deactivateSpinner,
    tradeDiscount,
    addToCart,
    editTradeObject,
    setIsEdit,
    setEditTradeObject,
  } = props;

  //-------inventory reducer
  const {
    allCategories,
    priceChartingProductInfo,
    inventoryProducts,
    productTags,
  } = inventory;

  //------UseRef
  const hiddenFileInput = useRef(null);
  //-------preset prices
  const { markupPresets, tradeinMargin } = tradeDiscount;
  //-------useState
  const [productType, setProductType] = useState(PRODUCT_TYPES_ENUMS.ALL);
  const [selectedTags, setSelectedTags] = useState([]);
  const [addSelectedTags, setAddSelectedTags] = useState([]);
  const [productDetail, setProductDetail] = useState("");
  const [conditionList, setConditionList] = useState([]);
  const [productCondition, setProductCondition] = useState();
  const [productTagsList, setProductTagsList] = useState([]);
  const [productUrl, setProductUrl] = useState("");
  const [pricechartingProductImage, setPricechartingProductImage] =
    useState("");
  const [productDataObject, setProductDataObject] = useState({
    productId: globalConstants.EMPTY_STRING,
    productName: globalConstants.EMPTY_STRING,
    consoleName: globalConstants.EMPTY_STRING,
    marketPrice: 0,
    suggestedSellPrice: 0,
    costOfGoods: 0,
    inStorePrice: 0,
    inStockPrice: 0,
    inStockQuantity: 0,
    skuNumber: 0,
    gameStopBuyPrice: 0,
    genre: globalConstants.EMPTY_STRING,
    upc: globalConstants.EMPTY_STRING,
    averageBuyPrice: 0,
    averageSellPrice: 0,
    maxBuyPrice: 0,
    cardNumber: 0,
    tcgPlayerUrl: globalConstants.EMPTY_STRING,
    imgUrl: globalConstants.EMPTY_STRING,
    epid: globalConstants.EMPTY_STRING,
  });
  const [rarities, setRarities] = useState(globalConstants.EMPTY_STRING);
  const [selectedRarity, setSelectedRarity] = useState(
    globalConstants.EMPTY_STRING
  );

  //-------useref
  const asyncSelectRef = useRef();

  //-------initial values
  const initialValues = {
    quantity: editTradeObject?.inStockQuantity || 1,
    box:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
      selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_BOX)
        ? true
        : false,
    manual:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
      !selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL)
        ? true
        : productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE &&
          selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_MANUAL)
        ? true
        : false,
    cables:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE
        ? selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.MISSING_WIRES)
          ? false
          : true
        : false,

    controller:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE
        ? selectedTags?.includes(
            inventoryConstants.PRODUCT_TAGS.MISSING_CONTROLLER
          )
          ? false
          : true
        : false,
    costOfGoods: editTradeObject?.costOfGoods || globalConstants.EMPTY_STRING,
    inStockPrice:
      editTradeObject?.inStockPrice ||
      productDataObject?.inStorePrice ||
      productDataObject?.inStockPrice ||
      globalConstants.EMPTY_STRING,
  };

  //------match tags of a poduct
  const isTagsEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  };

  //-------onselectedtagchange
  const onSelectTagsChange = (selectedTags) => {
    const selectedTagList = selectedTags.map((tag) => tag);
    const selectedTagListTags = selectedTags.map((tag) => tag.label);

    changePriceBaseOnTags(
      selectedTagListTags,
      productDataObject,
      setProductDataObject,
      priceChartingProductInfo,
      inventoryConstants,
      PRODUCT_CONDITIONS_ENUMS,
      TRADING_CARD_CATEGORIES_ENUMS,
      productCondition
    );

    setSelectedTags(selectedTagListTags);
    setAddSelectedTags(selectedTagList);
  };
  const getMarketPrice = () => {
    if (selectedRarity) {
      return Number(productDataObject?.marketPrice ?? 0);
    } else {
      return Number(convertToUsd(productDataObject.marketPrice));
    }
  };
  //-------handle inventory submit
  const handleAddInvetorySubmit = async (values) => {
    if (!productDataObject?.productName) {
      customToast("Please select some product", toastType.ERROR);
    } else {
      const sendToData = {
        ...editTradeObject,
        id: editTradeObject?.id,
        productId: productDataObject.productId,
        productName: productDataObject.productName,
        consoleName: productDataObject.consoleName,
        marketPrice: getMarketPrice(),
        suggestedSellPrice: productDataObject.suggestedSellPrice,
        costOfGoods: values.costOfGoods,
        inStockPrice: values.inStockPrice,
        inStockQuantity: values.quantity,
        skuNumber: 0,
        gameStopBuyPrice: editTradeObject.gameStopBuyPrice,
        genre: productDataObject.genre,
        upc: productDataObject.upc,
        averageBuyPrice: productDataObject.averageBuyPrice,
        averageSellPrice: productDataObject.averageSellPrice,
        maxBuyPrice: productDataObject.maxBuyPrice,
        cogsPercentage: editTradeObject?.cogsPercentage,
        imgUrl: productDataObject?.imgUrl,
        epid: productDataObject.epid || globalConstants.EMPTY_STRING,
        tcgPlayerUrl:
          productDataObject.tcgPlayerUrl || globalConstants.EMPTY_STRING,
        productType: productType,
        productCondition: productCondition?.value,
        productConditionList: conditionList,
        completePrice: editTradeObject.completePrice,
        loosePrice: editTradeObject.loosePrice,
        newPrice: editTradeObject.newPrice,
        boxOnlyPrice: editTradeObject.boxOnlyPrice,
        manualPrice: editTradeObject.manualPrice,
        apiSource: priceChartingProductInfo?.productMetaData?.sourceApi,
        raritiesList: editTradeObject?.raritiesList || [],
        rarity: selectedRarity || [],
        tags: addSelectedTags?.map((tag) => tag),
      };
      addToCart(sendToData);
      handleHideModal();
    }
  };

  //-------hide modal
  const handleHideModal = () => {
    setEditTradeObject("");
    setIsEdit(false);
    toggleAddInventoryItemModal();
    clearAddInventoryForm();
  };

  //-------empty product data object
  const emptyProductDataObject = () => {
    setProductDataObject({
      productId: globalConstants.EMPTY_STRING,
      productName: globalConstants.EMPTY_STRING,
      consoleName: globalConstants.EMPTY_STRING,
      marketPrice: 0,
      suggestedSellPrice: 0,
      costOfGoods: 0,
      inStockPrice: 0,
      inStorePrice: 0,
      inStockQuantity: 0,
      skuNumber: 0,
      gameStopBuyPrice: 0,
      genre: globalConstants.EMPTY_STRING,
      upc: globalConstants.EMPTY_STRING,
      averageBuyPrice: 0,
      averageSellPrice: 0,
      maxBuyPrice: 0,
      cardNumber: 0,
      tcgPlayerUrl: globalConstants.EMPTY_STRING,
      imgUrl: globalConstants.EMPTY_STRING,
      epid: globalConstants.EMPTY_STRING,
    });
  };

  //------find product details
  const findInventoryProductDetail = (condition) => {
    if (inventoryProducts?.length > 0) {
      const findProduct = inventoryProducts.find((product) => {
        if (
          product.price.type === condition &&
          isTagsEqual(product.tags || [], selectedTags || [])
        ) {
          if (
            priceChartingProductInfo?.productMetaData?.sourceApi ===
              inventoryConstants.POKEMON_API ||
            priceChartingProductInfo?.productMetaData?.sourceApi ===
              inventoryConstants.SCRYFALL_API
          ) {
            if (product.cardRarity === selectedRarity.label) {
              return product;
            }
          } else {
            return product;
          }
        }
      });
      setProductDataObject({
        ...productDataObject,
        inStockQuantity: findProduct?.price?.quantity || 0,
        skuNumber: findProduct?.sku || 0,
        inStorePrice: findProduct?.price?.unit_sell_price || 0,
        inStockPrice:
          findProduct?.price?.unit_sell_price ||
          getPresetStockPrice(
            productType,
            productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME ||
              productDataObject.consoleName ===
                TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
              ? convertToUsd(productDataObject.marketPrice)
              : productDataObject.marketPrice,
            markupPresets,
            productDataObject
          ),
        costOfGoods: findProduct?.price?.unit_purchase_price || 0,
        averageBuyPrice: findProduct?.price?.averageBuyPrice || 0,
        averageSellPrice: findProduct?.price?.averageSellPrice || 0,
        maxBuyPrice: findProduct?.price?.maxBuyPrice || 0,
      });
      return;
    }
    setProductDataObject({
      ...productDataObject,
      inStorePrice: 0,
      inStockPrice: getPresetStockPrice(
        productType,
        productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME ||
          productDataObject.consoleName ===
            TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
          ? convertToUsd(productDataObject.marketPrice)
          : productDataObject.marketPrice,
        markupPresets,
        productDataObject
      ),
    });
  };

  //-------clear form
  const clearAddInventoryForm = () => {
    emptyPriceChartingProductObject();
    emptyProductDataObject();
    setProductCondition("");
    setConditionList([]);
    setProductTagsList([]);
    setProductUrl("");
    setSelectedRarity("");
    setPricechartingProductImage("");
    setAddSelectedTags([]);
    setSelectedTags([]);
    setProductDetail("");
  };

  const handleRaritiesChange = (selectedOption, setFieldValue) => {
    if (selectedOption) {
      setSelectedRarity(selectedOption);
      var tradeInMarginToApply = getMarginDropdownValue(
        selectedOption?.value?.value,
        tradeinMargin,
        productType,
        productDataObject
      );
      const { cashOffer } = calculateOfferPrices(
        selectedOption?.value?.value,
        tradeInMarginToApply.marginObject.cashMarginPercentage,
        tradeInMarginToApply.marginObject.tradeinMarginPercentage
      );

      setFieldValue("costOfGoods", parseToDecimalNumber(cashOffer));
      setFieldValue(
        "inStockPrice",
        getPresetStockPrice(
          productType,
          selectedOption?.value?.value,
          markupPresets,
          productDataObject
        )
      );
      setProductDataObject({
        ...productDataObject,
        marketPrice: selectedOption?.value?.value,
      });
    }
  };

  //-------Change Logo image button
  const handleReplaceButtonClick = () => {
    hiddenFileInput.current.click();
  };

  //------handle upload button
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = async () => {
              activateSpinner(inventoryConstants.UPLOAD_IMAGE);
              const productImageUrl = await uploadCustomProductImage(file);
              setProductDataObject({
                ...productDataObject,
                imgUrl: productImageUrl,
              });
              deactivateSpinner(inventoryConstants.UPLOAD_IMAGE);
            };
          };
          reader.readAsDataURL(file);
        }
      } else {
        customToast(
          "Only .jpg, .jpeg and .png files are allowed",
          toastType.ERROR
        );
        e.target.value = null;
      }
    }
  };
  const getSuggestedSellPrice = (selectedOption) => {
    if (selectedOption?.value) {
      if (selectedOption.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE) {
        return priceChartingProductInfo?.productObject["retail-cib-sell"] || 0;
      } else if (selectedOption.value === PRODUCT_CONDITIONS_ENUMS.NEW) {
        return priceChartingProductInfo?.productObject["retail-new-sell"] || 0;
      } else if (selectedOption.value === PRODUCT_CONDITIONS_ENUMS.LOOSE) {
        return (
          priceChartingProductInfo?.productObject["retail-loose-sell"] || 0
        );
      } else if (
        selectedOption.value === PRODUCT_CONDITIONS_ENUMS.BOX_ONLY ||
        selectedOption.value === PRODUCT_CONDITIONS_ENUMS.MANUAL_ONLY
      ) {
        return 0;
      }
    }
    return 0; // Default value if no valid condition is selected
  };

  //-------UseEffect
  useEffect(() => {
    emptyPriceChartingProductObject();
  }, [currentStore]);

  useEffect(() => {
    if (productCondition?.value) {
      findInventoryProductDetail(productCondition?.value);
    }
  }, [selectedTags, selectedRarity, productCondition]);

  //-------update product condition and type
  useEffect(() => {
    if (addInventoryItemModal) {
      if (priceChartingProductInfo?.productObject) {
        if (
          priceChartingProductInfo.productMetaData.sourceApi ===
          inventoryConstants.PRICECHARTING_API
        ) {
          getPricechartingProductImage(
            `${process.env.REACT_APP_PRICECHARTING_IFRAME_URL}${stringToSlug(
              priceChartingProductInfo?.productObject["console-name"]
            )}/${stringToSlug(
              priceChartingProductInfo?.productObject["product-name"]
            )}`,
            setPricechartingProductImage
          );
          if (
            priceChartingProductInfo.productMetaData.genre ===
            TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
          ) {
            setSelectedRarity("");
            setProductType(PRODUCT_TYPES_ENUMS.TRADING_CARD);
            setConditionList(inventoryConstants.TRADING_CARD_CONDITION);
            // setProductCondition(inventoryConstants.TRADING_CARD_CONDITION[0]);
            setProductDataObject({
              ...productDataObject,
              productName:
                priceChartingProductInfo.productObject["product-name"],
              consoleName: TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD,
              // marketPrice:
              //   priceChartingProductInfo.productObject["loose-price"] || 0,
              marketPrice: editTradeObject?.marketPrice || 0,
              suggestedSellPrice:
                priceChartingProductInfo.productObject["retail-loose-sell"] ||
                0,
              gameStopBuyPrice:
                priceChartingProductInfo.productObject["gamestop-price"],
              upc:
                priceChartingProductInfo.productObject.upc ||
                globalConstants.EMPTY_STRING,
              genre: priceChartingProductInfo.productMetaData.genre,
              productId: priceChartingProductInfo.productObject.id,
              costOfGoods: 0,
              inStockPrice: 0,
              inStorePrice: 0,
              inStockQuantity: 0,
              skuNumber: 0,
              averageBuyPrice: 0,
              averageSellPrice: 0,
              maxBuyPrice: 0,
              cardNumber: 0,
              tcgPlayerUrl: globalConstants.EMPTY_STRING,
              imgUrl: globalConstants.EMPTY_STRING,
              epid:
                priceChartingProductInfo.productObject?.epid ||
                globalConstants.EMPTY_STRING,
            });
            getInventoryByPricechartingId(currentStore?.id, {
              id: priceChartingProductInfo.productObject.id,
              categoryName: TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD,
              productName:
                priceChartingProductInfo.productObject["product-name"],
            });
          } else {
            if (
              priceChartingProductInfo.productMetaData.genre ===
              TRADING_CARD_CATEGORIES_ENUMS.SYSTEM
            ) {
              handleConditionsAndTags(
                editTradeObject?.consoleName,
                editTradeObject.genre,
                productTags,
                setConditionList,
                setProductCondition,
                setProductTagsList,
                inventoryConstants.VIDEO_GAME_SYSTEM_CONDITION
              );
            } else {
              handleConditionsAndTags(
                editTradeObject?.consoleName,
                editTradeObject.genre,
                productTags,
                setConditionList,
                setProductCondition,
                setProductTagsList,
                inventoryConstants.VIDEO_GAME_CONDITION
              );
            }
            setSelectedRarity("");
            setProductType(PRODUCT_TYPES_ENUMS.VIDEO_GAME);
            setProductDataObject({
              ...productDataObject,
              productName:
                priceChartingProductInfo.productObject["product-name"],
              consoleName:
                priceChartingProductInfo?.productObject["console-name"],
              marketPrice: editTradeObject?.marketPrice,
              suggestedSellPrice: getSuggestedSellPrice(productCondition),
              gameStopBuyPrice:
                priceChartingProductInfo.productObject["gamestop-price"],
              upc:
                priceChartingProductInfo.productObject.upc ||
                globalConstants.EMPTY_STRING,
              genre: priceChartingProductInfo.productMetaData.genre,
              productId: priceChartingProductInfo.productObject.id,
              costOfGoods: 0,
              inStockPrice: 0,
              inStorePrice: 0,
              inStockQuantity: 0,
              skuNumber: 0,
              averageBuyPrice: 0,
              averageSellPrice: 0,
              maxBuyPrice: 0,
              cardNumber: 0,
              tcgPlayerUrl: globalConstants.EMPTY_STRING,
              imgUrl: globalConstants.EMPTY_STRING,
              epid:
                priceChartingProductInfo.productObject?.epid ||
                globalConstants.EMPTY_STRING,
            });
            getInventoryByPricechartingId(currentStore?.id, {
              id: priceChartingProductInfo.productObject.id,
              categoryName:
                priceChartingProductInfo.productObject["console-name"],
              productName:
                priceChartingProductInfo.productObject["product-name"],
            });
          }
        } else if (
          priceChartingProductInfo.productMetaData.sourceApi ===
            inventoryConstants.POKEMON_API ||
          priceChartingProductInfo.productMetaData.sourceApi ===
            inventoryConstants.SCRYFALL_API
        ) {
          setProductType(PRODUCT_TYPES_ENUMS.TRADING_CARD);
          setConditionList(inventoryConstants.TRADING_CARD_CONDITION);
          setProductCondition(inventoryConstants.TRADING_CARD_CONDITION[0]);
          setProductTagsList([]);
          if (
            priceChartingProductInfo.productMetaData.sourceApi ===
            inventoryConstants.POKEMON_API
          ) {
            const raritiesList = priceChartingProductInfo?.productObject
              ?.tcgplayer?.prices
              ? Object.keys(
                  priceChartingProductInfo.productObject.tcgplayer.prices
                ).map((key, index) => {
                  const value = `${JSON.stringify(
                    priceChartingProductInfo.productObject.tcgplayer.prices[key]
                      .market
                  )}`;
                  return { label: key, value: { key: index, value: value } };
                })
              : [];

            setRarities(raritiesList);
            setSelectedRarity(raritiesList[0]);

            const pokemonProductName = `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set.name} - ${priceChartingProductInfo.productObject.number}/${priceChartingProductInfo.productObject.set.printedTotal})`;
            getInventoryByPricechartingId(currentStore?.id, {
              id: priceChartingProductInfo.productObject.id,
              categoryName: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
              productName: pokemonProductName,
            });
            setProductDataObject({
              ...productDataObject,
              productName: pokemonProductName,
              consoleName: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
              upc:
                priceChartingProductInfo.productObject.upc ||
                globalConstants.EMPTY_STRING,
              genre: priceChartingProductInfo.productMetaData.genre,
              productId: priceChartingProductInfo.productObject.id,
              marketPrice: editTradeObject?.marketPrice,
              suggestedSellPrice: 0,
              cardNumber: priceChartingProductInfo.productObject.number,
              imgUrl:
                priceChartingProductInfo.productObject.images.small ||
                globalConstants.EMPTY_STRING,
              tcgPlayerUrl:
                priceChartingProductInfo?.productObject?.tcgplayer?.url ||
                EMPTY_STRING,
              costOfGoods: 0,
              inStorePrice: 0,
              inStockPrice: 0,
              inStockQuantity: 0,
              skuNumber: 0,
              gameStopBuyPrice: 0,
              averageBuyPrice: 0,
              averageSellPrice: 0,
              maxBuyPrice: 0,
              epid: globalConstants.EMPTY_STRING,
            });
          } else if (
            priceChartingProductInfo.productMetaData.sourceApi ===
            inventoryConstants.SCRYFALL_API
          ) {
            const priceList = convertPricesToNumbers(
              priceChartingProductInfo?.productObject?.prices
            );
            const raritiesList = priceList
              ? Object.keys(priceList).map((key, index) => {
                  const value = `${JSON.stringify(priceList[key])}`;
                  return {
                    label: key,
                    value: { key: index, value: value === "null" ? 0 : value },
                  };
                })
              : [];

            setRarities(raritiesList);
            setSelectedRarity(raritiesList[0]);
            getInventoryByPricechartingId(currentStore?.id, {
              id: priceChartingProductInfo.productObject.id,
              categoryName: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
              productName: `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set_name} - ${priceChartingProductInfo.productObject.collector_number})`,
            });
            setProductDataObject({
              ...productDataObject,
              productName: `${priceChartingProductInfo.productObject.name} (${priceChartingProductInfo.productObject.set_name} - ${priceChartingProductInfo.productObject.collector_number})`,
              consoleName: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
              upc:
                priceChartingProductInfo.productObject.upc ||
                globalConstants.EMPTY_STRING,
              genre: priceChartingProductInfo.productMetaData.genre,
              productId: priceChartingProductInfo.productObject.id,
              marketPrice: editTradeObject?.marketPrice || 0,
              suggestedSellPrice: 0,
              cardNumber:
                priceChartingProductInfo.productObject.collector_number,
              tcgPlayerUrl:
                priceChartingProductInfo.productObject.purchase_uris
                  ?.tcgplayer || globalConstants.EMPTY_STRING,
              imgUrl: "",
              costOfGoods: 0,
              inStockPrice: 0,
              inStorePrice: 0,
              inStockQuantity: 0,
              skuNumber: 0,
              gameStopBuyPrice: 0,
              averageBuyPrice: 0,
              averageSellPrice: 0,
              maxBuyPrice: 0,
              imgUrl:
                priceChartingProductInfo.productObject.image_uris?.small ||
                globalConstants.EMPTY_STRING,
              epid: globalConstants.EMPTY_STRING,
            });
          }
        }
      }
      setProductUrl("");
    }
  }, [priceChartingProductInfo.productObject]);

  useEffect(() => {
    setProductDataObject({
      ...productDataObject,
      imgUrl: pricechartingProductImage,
    });
  }, [pricechartingProductImage]);

  useEffect(() => {
    if (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE) {
      findInventoryProductDetail(PRODUCT_CONDITIONS_ENUMS.COMPLETE);
    } else if (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.NEAR_MINT) {
      findInventoryProductDetail(PRODUCT_CONDITIONS_ENUMS.NEAR_MINT);
    }
  }, [inventoryProducts]);

  // new One
  useEffect(() => {
    const productCondition = {
      value: editTradeObject?.productCondition,
      label: handleShowNintendoCondition(
        editTradeObject.consoleName,
        editTradeObject?.productCondition
      ),
    };
    setProductCondition(productCondition);
    setProductTagsList(
      handleTagList(
        productTags,
        editTradeObject?.genre,
        productCondition.value,
        ""
      )
    );
    // onChangeTag(editTradeObject?.productCondition, editTradeObject);

    setRarities(
      editTradeObject?.raritiesList?.length > 0
        ? editTradeObject?.raritiesList
        : ""
    );
    setSelectedRarity(
      editTradeObject?.rarity?.label ? editTradeObject?.rarity : ""
    );

    setProductDetail(editTradeObject);
  }, [editTradeObject, pricechartingProductImage, priceChartingProductInfo]);

  return (
    <Modal
      show={addInventoryItemModal}
      size="lg"
      onHide={handleHideModal}
      animation={true}
      centered
      backdrop="static"
      className={`add-inventory-modal ${productUrl ? "iframe-opened" : ""}`}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleAddInvetorySubmit}
        validate={addNewIventoryValidationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Modal.Header
              closeButton
              className="add-inventory-modal-header  pt-0 pb-1"
            >
              <Modal.Title>
                <span className="add-inventory-modal-header-name">
                  Edit Batch Inventory
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="add-inventory-modal-body d-flex ">
              <Row className={`m-0 ${productUrl ? "w-50" : "w-100"}`}>
                {/* <Col md={12} className="mb-3 ps-md-0">
                  <SearchInventory
                    asyncSelectRef={asyncSelectRef}
                    allCategories={allCategories}
                  />
                </Col> */}
                <Col xs={12} className="add-inventory-hero-wrapper px-0">
                  <Row className="m-0">
                    <Col md={8} className="d-flex gap-3">
                      <div className="add-inventory-product-image">
                        <img src={productDataObject?.imgUrl || ProductImage} />
                        <input
                          type="file"
                          className="d-none"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleImageChange}
                          ref={hiddenFileInput}
                        />
                        <button
                          onClick={handleReplaceButtonClick}
                          type="button"
                          className="replace-logo-button"
                        >
                          <LogoImageReplaceIcon className="replace-icon" />
                        </button>
                      </div>

                      <div className="d-flex flex-column justify-content-between align-items-start product-content-wrapper">
                        <span>
                          {productDataObject.productName || "Product Name"}
                        </span>
                        <span>
                          {productDataObject.consoleName || "Console Name"}
                        </span>
                        <span>
                          {productDataObject?.skuNumber?.length
                            ? `#${getShortestSku(productDataObject?.skuNumber)}`
                            : "#Auto Generated"}
                        </span>
                        <span>{`In Stock: ${productDataObject.inStockQuantity}`}</span>
                      </div>
                    </Col>

                    {priceChartingProductInfo?.productMetaData?.sourceApi ===
                    inventoryConstants.PRICECHARTING_API ? (
                      <Col
                        md={4}
                        className="d-flex flex-column align-items-center gap-2"
                      >
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_EBAY_SALES}
                          className="w-100"
                          handleClick={() =>
                            handleViewEbaySales(
                              productDataObject?.productName,
                              productDataObject?.consoleName,
                              productDataObject?.genre
                            )
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        />
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_PRICE_CHARTING}
                          className="w-100"
                          handleClick={() =>
                            handleViewPriceCharting(
                              priceChartingProductInfo,
                              setProductUrl
                            )
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                        />
                      </Col>
                    ) : priceChartingProductInfo?.productMetaData?.sourceApi ===
                        inventoryConstants.SCRYFALL_API ||
                      priceChartingProductInfo?.productMetaData?.sourceApi ===
                        inventoryConstants.POKEMON_API ? (
                      <Col
                        md={4}
                        className="d-flex flex-column align-items-center gap-2"
                      >
                        <Button
                          type="button"
                          label={buttonNameConstants.VIEW_TCG_PLAYER}
                          className="w-100"
                          handleClick={() =>
                            handleViewTCGPlayer(productDataObject)
                          }
                          buttonType={buttonTypeConstants.LIGHT_PURPLE}
                          isDisabled={!productDataObject?.tcgPlayerUrl}
                        />
                      </Col>
                    ) : null}

                    <Col
                      md={12}
                      className="d-flex justify-content-start flex-wrap gap-3 mt-3 pt-2"
                    >
                      <span className="quantity-top-margin">Quantity:</span>
                      <InputNumberField
                        name="quantity"
                        placeHolder="0"
                        className="add-inventory-quantity-field"
                        isConvertToDecimal={false}
                      />
                      <CategoriesDropdown
                        options={conditionList}
                        handleChange={(selectedOption) =>
                          handleConditionChange(
                            selectedOption,
                            setProductCondition,
                            priceChartingProductInfo,
                            productTags,
                            setProductTagsList,
                            setProductDataObject,
                            productDataObject,
                            setSelectedTags
                          )
                        }
                        noOptionsMessage="No Condition Found"
                        placeholder="Condition"
                        SelectIconImage={
                          categoryDropdownConstants.INVENTORY_LIST_ICON
                        }
                        isClearable={false}
                        className="add-inventory-dropdowns-category condition-dropdown"
                        value={productCondition}
                      />
                      {productDataObject?.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
                      productDataObject?.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD ? (
                        <CategoriesDropdown
                          options={rarities || []}
                          handleChange={(selectedOption) =>
                            handleRaritiesChange(selectedOption, setFieldValue)
                          }
                          noOptionsMessage="No Printing Found"
                          placeholder="Printings"
                          SelectIconImage={
                            categoryDropdownConstants.INVENTORY_LIST_ICON
                          }
                          isClearable={false}
                          className="add-inventory-dropdowns-category condition-dropdown"
                          value={selectedRarity}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                    {productCondition?.value ===
                      PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                    productCondition?.value ===
                      PRODUCT_CONDITIONS_ENUMS.LOOSE ? (
                      <Col
                        md={12}
                        className="d-flex align-items-center gap-3 my-2"
                      >
                        {productDataObject.genre ===
                        TRADING_CARD_CATEGORIES_ENUMS.SYSTEM ? (
                          productCondition?.value ===
                          PRODUCT_CONDITIONS_ENUMS.COMPLETE ? (
                            <>
                              <CheckboxField name="manual" label="Manual" />
                              {/* <CheckboxField name="box" label="Box" /> */}
                              <CheckboxField name="cables" label="Cables" />
                              <CheckboxField
                                name="controller"
                                label="Controller"
                              />
                            </>
                          ) : productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.LOOSE ? (
                            <>
                              <CheckboxField name="manual" label="Manual" />
                              <CheckboxField name="cables" label="Cables" />
                              <CheckboxField
                                name="controller"
                                label="Controller"
                              />
                            </>
                          ) : (
                            ""
                          )
                        ) : [
                            ...inventoryConstants.NINTENDO_CATEGORIES,
                            ...inventoryConstants.SKYLANDER_CATEGORIES,
                          ].includes(editTradeObject?.consoleName) ? (
                          ""
                        ) : (
                          <CheckboxField name="manual" label="Manual" />
                        )}
                      </Col>
                    ) : (
                      ""
                    )}

                    <Col
                      md={12}
                      className="my-2 d-flex align-items-center gap-4"
                    >
                      <ProductTagList
                        tagsList={productTagsList || []}
                        suggestedArrayList={productTags}
                        onSelectTagsChange={onSelectTagsChange}
                        productDetail={productDetail}
                        selectedManual={{
                          isChecked: values.manual,
                          value:
                            productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE
                              ? inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL
                              : productCondition?.value ===
                                PRODUCT_CONDITIONS_ENUMS.LOOSE
                              ? inventoryConstants.PRODUCT_TAGS.WITH_MANUAL
                              : "",
                        }}
                        selectedWires={{
                          isChecked: values.cables,
                          value:
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                            productCondition?.value ===
                              PRODUCT_CONDITIONS_ENUMS.LOOSE
                              ? inventoryConstants.PRODUCT_TAGS.MISSING_WIRES
                              : "",
                        }}
                        selectedController={{
                          isChecked: values.controller,
                          value:
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                            productCondition?.value ===
                              PRODUCT_CONDITIONS_ENUMS.LOOSE
                              ? inventoryConstants.PRODUCT_TAGS
                                  .MISSING_CONTROLLER
                              : "",
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} className="m-0 p-0 mt-4">
                  <Row className="m-0">
                    <Col
                      md={4}
                      className="add-inventory-prices-detail-wrapper d-flex flex-column align-items-lg-stretch gap-3"
                    >
                      <div>
                        <span>GameStop Buy Price</span>
                        <span>{`$${convertToUsd(
                          productDataObject.gameStopBuyPrice
                        )}`}</span>
                      </div>
                      <div>
                        <span>Average Buy Price</span>
                        <span>{`$${
                          productDataObject.averageBuyPrice || 0
                        }`}</span>
                      </div>
                      <div>
                        <span>Max Buy Price</span>
                        <span>{`$${productDataObject.maxBuyPrice || 0}`}</span>
                      </div>
                      <div>
                        <span>Average Sell Price</span>
                        <span>{`$${
                          productDataObject.averageSellPrice || 0
                        }`}</span>
                      </div>
                    </Col>
                    <Col md={8}>
                      <Row className="m-0 h-100">
                        <Col xs={12} className="d-flex flex-column gap-1">
                          <div className="w-100 d-flex justify-content-between add-inventory-price-heading">
                            <span>Market Price: </span>
                            <span>
                              {`$${
                                selectedRarity
                                  ? productDataObject?.marketPrice &&
                                    productDataObject?.marketPrice !== null
                                    ? productDataObject?.marketPrice
                                    : 0
                                  : convertToUsd(productDataObject.marketPrice)
                              }`}
                            </span>
                          </div>
                          <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                            <span>Suggested sell price: </span>
                            <span>
                              {`$${convertToUsd(
                                productDataObject.suggestedSellPrice
                              )}`}
                            </span>
                          </div>
                          <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                            <span>In-Stock Price: </span>
                            <span>
                              {`$${productDataObject.inStorePrice || 0}`}{" "}
                            </span>
                          </div>
                        </Col>
                        <Col xs={12} className="d-flex flex-column gap-1">
                          <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                            <span>Cost of Goods: </span>
                            <InputNumberField
                              name="costOfGoods"
                              placeHolder="0"
                              className="add-inventory-price-field"
                              IconImage={DollarIcon}
                            />
                          </div>
                        </Col>
                        <Col xs={12} className="d-flex flex-column gap-3">
                          <div className="add-inventory-stock-price-wrapper">
                            <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                              <span>Stock Price </span>
                            </div>
                            <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                              <span>In-Store: </span>
                              <InputNumberField
                                name="inStockPrice"
                                placeHolder="0"
                                className="add-inventory-price-field"
                                IconImage={DollarIcon}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={12} className="d-flex align-items-end gap-2">
                          <Button
                            label={buttonNameConstants.CANCEL}
                            className="w-100"
                            type="button"
                            handleClick={() => handleHideModal()}
                            buttonType={buttonTypeConstants.GHOST_BUTTON}
                          />
                          <Button
                            label={buttonNameConstants.SAVE}
                            className="w-100"
                            type="submit"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {productUrl && (
                <Row className="m-0 w-50">
                  <Col md={12}>
                    {productUrl && (
                      <iframe
                        title="Product Iframe"
                        src={productUrl}
                        className="iframe-website"
                      />
                    )}
                  </Col>
                </Row>
              )}
            </Modal.Body>
          </Form>
        )}
      </Formik>
      {productUrl && (
        <button
          type="button"
          onClick={() => {
            setProductUrl("");
          }}
          className="iframe-toogle-button"
        >
          <SidebarToggleIcon className={"iframe-toggle-icon"} />
        </button>
      )}
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  getInventoryByPricechartingId: (storeId, data) =>
    dispatch(inventoryActions.getInventoryByPricechartingId(storeId, data)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
});

//-------Export AddNewUser Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBatchInventoryEdit);
