import { transactionService } from "../../services";
import { custom, failure, success } from "./actionsUtilities";
import { storeActions, systemActions, customerActions } from "../actions";
import {
  toastType,
  toastMessages,
  systemConstants,
  globalConstants,
  routesPathConstants,
  transactionConstants,
  transactionDefaultData,
  tradeTransactionDefaultData,
  inventoryConstants,
} from "../../constants";
import {
  customToast,
  parseToNumber,
  parseToDecimalNumber,
  getSumOfPaymentHistory,
  updateCartLocalStorage,
  convertToFixedPrecision,
  getCountOfTradeTotalItems,
  parseToThousandSeperatorDecimalNumber,
} from "../../shared/utility";
import { EMAIL_MESSAGE_TYPES } from "../../system/globalEnums";

//-------Get all Transaction
const getAllTransaction = (storeId, type, start, end, cat) => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.GET_ALL_TRANSACTION,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    transactionService
      .getAllTransaction(storeId, type, start, end, cat)
      .then(
        (response) => {
          dispatch(success(response, transactionConstants.GET_ALL_TRANSACTION));
        },
        (error) => {
          dispatch(failure([], transactionConstants.GET_ALL_TRANSACTION));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.GET_ALL_TRANSACTION,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get all draft
const getAllDraft = (storeId, type, start, end, cat) => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.GET_ALL_DRAFT,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    transactionService
      .getAllDraft(storeId, type, start, end, cat)
      .then(
        (response) => {
          dispatch(success(response, transactionConstants.GET_ALL_DRAFT));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.GET_ALL_DRAFT,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get search Transaction
const getSearchTransaction = (storeId, id) => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.GET_SEARCH_TRANSACTION,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    transactionService
      .getSearchTransaction(storeId, id)
      .then(
        (response) => {
          dispatch(
            success(response, transactionConstants.GET_SEARCH_TRANSACTION)
          );
        },
        (error) => {
          dispatch(failure([], transactionConstants.GET_SEARCH_TRANSACTION));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.GET_SEARCH_TRANSACTION,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------emptySearchTransaction
const emptySearchTransaction = () => {
  return (dispatch) => {
    dispatch(success([], transactionConstants.GET_SEARCH_TRANSACTION));
  };
};

//-------addReturn
const addReturn = (returnData, storeId) => {
  return (dispatch) => {
    dispatch(
      custom(transactionConstants.ADD_RETURN, systemConstants.SPINNER_ACTIVATE)
    );
    transactionService
      .addReturn(returnData, storeId)
      .then(
        (response) => {
          customToast(
            toastMessages.RETURN_HAS_BEEN_SUCCESSFULL,
            toastType.SUCCESS
          );
          dispatch(
            systemActions.pageRedireaction(
              routesPathConstants.INVENTORY_ITEMS_HISTORY
            )
          );
          dispatch(success([], transactionConstants.GET_SEARCH_TRANSACTION));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.ADD_RETURN,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------getReturnId
const getReturnId = () => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.GET_RETURN_ID,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    transactionService
      .getReturnId()
      .then(
        (response) => {
          dispatch(success(response, transactionConstants.GET_RETURN_ID));
        },
        (error) => {
          dispatch(failure([], transactionConstants.GET_RETURN_ID));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.GET_RETURN_ID,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get all draft
const getGiftCardDetails = (storeId, barcode) => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.GET_GIFT_CARD_DETAILS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    transactionService
      .getGiftCardDetails(storeId, barcode)
      .then(
        (response) => {
          dispatch(
            success(response, transactionConstants.GET_GIFT_CARD_DETAILS)
          );
        },
        (error) => {
          dispatch(
            success(
              globalConstants.EMPTY_STRING,
              transactionConstants.GET_GIFT_CARD_DETAILS
            )
          );
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.GET_GIFT_CARD_DETAILS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const emptyGiftCardDetails = () => {
  return (dispatch) => {
    dispatch(
      success(
        globalConstants.EMPTY_STRING,
        transactionConstants.GET_GIFT_CARD_DETAILS
      )
    );
  };
};

const setTransactionData = (transactionData, storeId) => {
  return (dispatch) => {
    if (storeId) {
      updateCartLocalStorage(
        transactionConstants.NEW_SALE_PAGE_DATA,
        transactionData,
        storeId
      );

      dispatch(
        custom(transactionData, transactionConstants.SET_TRANSACTION_DATA)
      );
    }
  };
};

const setNewTradeData = (tardeData, storeId) => {
  return (dispatch) => {
    if (storeId) {
      updateCartLocalStorage(
        transactionConstants.NEW_TRADE_PAGE_DATA,
        tardeData,
        storeId
      );

      dispatch(custom(tardeData, transactionConstants.SET_NEW_TRADE_DATA));
    }
  };
};

const setAddTradeBatchInventory = (tardeBatchData, storeId) => {
  return (dispatch) => {
    if (storeId) {
      updateCartLocalStorage(
        transactionConstants.TRADE_BATCH_DATA,
        tardeBatchData,
        storeId
      );
      dispatch(
        success(
          tardeBatchData,
          transactionConstants.SET_ADD_TRADE_BATCH_INVENTORY
        )
      );
    }
  };
};

//-------Page Redireaction
const toggleAddCustomerModal = (value) => {
  return (dispatch) => {
    dispatch(custom(value, transactionConstants.TOGGLE_ADD_CUSTOMER_MODAL));
  };
};

//-------Get all draft
const newSaleTransaction = (
  data,
  transactionObject,
  handlePaymentMethod,
  handlePrintReceipt,
  isAutoPrint,
  isUndoTransaction = false,
  message
) => {
  return (dispatch) => {
    if (data.PaymentType !== transactionConstants.CREDIT_CARD) {
      dispatch(
        custom(
          transactionConstants.MAKE_TRANSACTION,
          systemConstants.SPINNER_ACTIVATE
        )
      );
    }

    transactionService
      .newSaleTransaction(
        {
          ...data,
        },
        isUndoTransaction
      )
      .then(
        (response) => {
          const updatedCustomer =
            data.PaymentType === transactionConstants.STORE_CREDIT
              ? {
                  ...transactionObject.customer,
                  currentBalance:
                    parseToNumber(transactionObject.currentAmountToPay) >
                    parseToNumber(transactionObject.customer.currentBalance)
                      ? parseToDecimalNumber(0)
                      : parseToThousandSeperatorDecimalNumber(
                          parseToNumber(
                            transactionObject.customer.currentBalance
                          ) -
                            parseToNumber(transactionObject.currentAmountToPay)
                        ),
                }
              : transactionObject.customer;
          const updatedTransactionData = {
            ...transactionObject,
            ...response,
            store: transactionObject.store,
            customer: updatedCustomer,
            paymentMethod:
              data.TransactionType === transactionConstants.DRAFT
                ? globalConstants.EMPTY_STRING
                : transactionConstants.PAYMENT_COMPLETE,
            currentAmountToPay: Number(
              response.totalAmountToPay -
                getSumOfPaymentHistory(response.paymentHistory)
            ).toFixed(2),
            poiId: globalConstants.EMPTY_STRING,
            giftCard: globalConstants.EMPTY_OBJECT,
            paymentDevice: globalConstants.EMPTY_STRING,
            inventory: [...response.inventory],
            createdOn: response.createdOn,
          };

          if (
            updatedTransactionData?.paymentMethod ===
              transactionConstants.PAYMENT_COMPLETE &&
            isAutoPrint
          ) {
            handlePrintReceipt(updatedTransactionData);
          }
          dispatch(
            setTransactionData(
              updatedTransactionData,
              transactionObject.store.id
            )
          );

          dispatch(emptyGiftCardDetails());
          customToast(message, toastType.SUCCESS);
          if (data.PaymentType === transactionConstants.STORE_CREDIT) {
            dispatch(
              customerActions.getAllCustomer(data.companyId, data.store.id)
            );
          }
        },
        (error) => {
          handlePaymentMethod(globalConstants.EMPTY_STRING);
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.MAKE_TRANSACTION,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const handleNewParkSale = (transactionData, currentStore) => {
  const dataToSend = {
    id: transactionData.id,
    Tax: transactionData?.tax,
    note: transactionData.note,
    discount: transactionData.discount,
    SubTotal: transactionData?.subTotal,
    poiId: globalConstants.EMPTY_STRING,
    inventory: transactionData.inventory,
    creditCard: transactionData.creditCard,
    giftCard: globalConstants.EMPTY_OBJECT,
    creditUsed: transactionData.creditUsed,
    isNoteField: transactionData.isNoteField,
    TransactionType: transactionConstants.DRAFT,
    PaymentType: transactionConstants.PARK_SALE,
    isTaxApplied: transactionData.isTaxApplied,
    cashChangeOwed: transactionData.cashChangeOwed,
    discountMethod: transactionData.discountMethod,
    paymentHistory: transactionData?.paymentHistory,
    isDiscountField: transactionData.isDiscountField,
    totalAmountToPay: transactionData?.totalAmountToPay,
    discountedValue: transactionData.discountedValue || 0,
    currentAmountToPay: transactionData.currentAmountToPay,
    cashTenderedAmount: transactionData.cashTenderedAmount,
    activeCardPaymentType: currentStore.activeCardPaymentType,
    giftCardPaymentHistory: transactionData.giftCardPaymentHistory,
    paymentTypeOrder: transactionData.paymentTypeOrder,
    store: {
      id: currentStore?.id,
      name: currentStore?.storeName,
    },
    customer: transactionData.customer
      ? transactionData.customer
      : { id: "", name: "" },
  };
  return (dispatch) => {
    dispatch(parkSale(dataToSend));
  };
};

//-------Bulk Upload Transaction History
const bulkUploadTransactionHistory = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.BULK_UPLOAD_HISTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    transactionService
      .bulkUploadTransactionHistory(storeId, data)
      .then(
        (response) => {
          dispatch(
            success(
              response?.data?.data,
              transactionConstants.BULK_UPLOAD_HISTORY
            )
          );
          customToast(response?.data?.message, toastType.SUCCESS);
          dispatch(getAllTransaction(storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.BULK_UPLOAD_HISTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get all draft
const cancelCardTransaction = (data, transactionObject) => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.CANCEL_TRANSACTION,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    transactionService
      .cancelCardTransaction(data)
      .then(
        (response) => {
          customToast(
            toastMessages.TRANSACTION_CANCEL_SUCCESSFULLY,
            toastType.SUCCESS
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          setTransactionData(
            {
              ...transactionObject,
              poiId: globalConstants.EMPTY_STRING,
              paymentDevice: globalConstants.EMPTY_STRING,
              paymentMethod: globalConstants.EMPTY_STRING,
            },
            transactionObject.store.id
          )
        );
        dispatch(
          custom(
            transactionConstants.CANCEL_TRANSACTION,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};
const calculation = (newTradeData, response) => {
  const paymentStatus =
    response.totalAmountToPay - getSumOfPaymentHistory(response.paymentHistory)
      ? globalConstants.EMPTY_STRING
      : transactionConstants.PAYMENT_COMPLETE;
  if (paymentStatus === globalConstants.EMPTY_STRING) {
    if (response.PaymentType === globalConstants.CASH) {
      const percentage =
        (response.currentAmountToPay * 100) / newTradeData.totalCashOffer;
      const tradePercentage = (newTradeData.totalTradeOffer * percentage) / 100;
      const tradeValue = newTradeData.totalTradeOffer - tradePercentage;
      return tradeValue;
    } else {
      const percentage =
        (response.currentAmountToPay * 100) / newTradeData.totalTradeOffer;
      const cashPercentage = (newTradeData.totalCashOffer * percentage) / 100;
      const cashValue = newTradeData.totalCashOffer - cashPercentage;
      return cashValue;
    }
  } else {
    return 0;
  }
};

//-------newTradeTransaction
const newTradeTransaction = (data, newTradeData, storeId, companyId) => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.MAKE_TRADE_TRANSACTION,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    transactionService
      .newTradeTransaction(data, storeId)
      .then(
        (response) => {
          let updatedTradeData = {};
          if (data.SplitCheck === transactionConstants.DRAFT) {
            updatedTradeData = {
              ...newTradeData,
              ...response,
              inventory: response.inventory,
              tax:
                newTradeData?.userPaymentType === globalConstants.CASH
                  ? newTradeData.taxForTrade
                  : 0,
              store: newTradeData.store,
              customer: newTradeData.customer,
              subTotal:
                newTradeData?.userPaymentType === globalConstants.CASH
                  ? convertToFixedPrecision(
                      newTradeData.totalTradeOffer - newTradeData.taxForTrade
                    )
                  : convertToFixedPrecision(newTradeData.totalCashOffer),
              totalAmountToPay:
                convertToFixedPrecision(calculation(newTradeData, response)) +
                getSumOfPaymentHistory(response.paymentHistory),

              // totalAmountToPay:
              //   newTradeData?.userPaymentType == globalConstants.CASH
              //     ? newTradeData.totalTradeOffer
              //     : newTradeData.totalCashOffer,

              paymentMethod:
                response.totalAmountToPay -
                getSumOfPaymentHistory(response.paymentHistory)
                  ? globalConstants.EMPTY_STRING
                  : transactionConstants.PAYMENT_COMPLETE,
              amountToPay: convertToFixedPrecision(
                calculation(newTradeData, response)
              ),
              poiId: globalConstants.EMPTY_STRING,
              giftCard: globalConstants.EMPTY_OBJECT,
              userPaymentType:
                newTradeData?.userPaymentType === globalConstants.CASH
                  ? globalConstants.CREDIT
                  : globalConstants.CASH,
            };
            customToast(
              "Trade trasaction saved in draft sucessfull",
              toastType.SUCCESS
            );
          } else if (data.SplitCheck === transactionConstants.PROCESSED) {
            updatedTradeData = {
              ...newTradeData,
              ...response,
              store: newTradeData.store,
              customer: newTradeData.customer,
              paymentMethod:
                response.totalAmountToPay -
                getSumOfPaymentHistory(response.paymentHistory)
                  ? globalConstants.EMPTY_STRING
                  : transactionConstants.PAYMENT_COMPLETE,
              amountToPay: Number(
                response.totalAmountToPay -
                  getSumOfPaymentHistory(response.paymentHistory)
              ).toFixed(2),
              // amountToPay: calculation(newTradeData, response),
              poiId: globalConstants.EMPTY_STRING,
              giftCard: globalConstants.EMPTY_OBJECT,
            };

            const isAloneQuickTrade =
              newTradeData?.inventory?.length === 1 &&
              newTradeData?.inventory[0]?.product_id ===
                transactionConstants.QUICK_TRADE;
            dispatch(
              storeActions.getUserNotifications(
                data.store.id,
                true,
                `${
                  isAloneQuickTrade
                    ? newTradeData?.inventory[0]?.price?.totalItems
                    : getCountOfTradeTotalItems(
                        newTradeData.inventory || 0,
                        "quantity"
                      )
                } items added successfully.`,
                false,
                !isAloneQuickTrade
              )
            );
          } else {
            updatedTradeData = {
              ...newTradeData,
              ...response,
              store: newTradeData.store,
              customer: newTradeData.customer,

              paymentMethod:
                (response.paymentHistory?.totalPaidAmount[
                  transactionConstants.CASH
                ] > 0 &&
                  response.paymentHistory?.totalPaidAmount[
                    transactionConstants.STORE_CREDIT
                  ]) > 0
                  ? transactionConstants.PAYMENT_COMPLETE
                  : globalConstants.EMPTY_STRING,
              amountToPay: 0,
              // amountToPay: calculation(newTradeData, response),
              poiId: globalConstants.EMPTY_STRING,
              giftCard: globalConstants.EMPTY_OBJECT,
              createdOn: response.createdOn,
            };

            customToast("Trade Transaction successfull", toastType.SUCCESS);
            if (data.TransactionType === transactionConstants.PROCESSED) {
              const isAloneQuickTrade =
                newTradeData?.inventory?.length === 1 &&
                newTradeData?.inventory[0]?.product_id ===
                  transactionConstants.QUICK_TRADE;
              dispatch(
                storeActions.getUserNotifications(
                  data.store.id,
                  true,
                  `${
                    isAloneQuickTrade
                      ? newTradeData?.inventory[0]?.price?.totalItems
                      : getCountOfTradeTotalItems(
                          newTradeData.inventory || 0,
                          "quantity"
                        )
                  } items added successfully.`,
                  false,
                  !isAloneQuickTrade
                )
              );
            }
          }
          if (
            updatedTradeData.paymentMethod ===
            transactionConstants.PAYMENT_COMPLETE
          ) {
            dispatch(customerActions.getAllCustomer(companyId, storeId));
          }

          dispatch(setNewTradeData(updatedTradeData, storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.MAKE_TRADE_TRANSACTION,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Get park sale
const parkSale = (data) => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.MAKE_TRANSACTION,
        systemConstants.SPINNER_ACTIVATE
      )
    );

    transactionService
      .newSaleTransaction(data)
      .then(
        (response) => {
          dispatch(setTransactionData(transactionDefaultData, data.store.id));
          dispatch(emptyGiftCardDetails());
          customToast(
            toastMessages.TRANSACTION_DRAFT_SUCCESSFULLY,
            toastType.SUCCESS
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.MAKE_TRANSACTION,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const getTradeId = () => {
  return transactionService.getTradeId().then(
    (response) => {
      if (response) {
        return response;
      }
    },
    (error) => {
      customToast(error, toastType.ERROR);
    }
  );
};

//-------newTradePark
const newTradePark = (data, storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.MAKE_TRADE_TRANSACTION,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    transactionService
      .newTradePark(data, storeId)
      .then(
        (response) => {
          dispatch(setNewTradeData(tradeTransactionDefaultData, storeId));
          dispatch(success([], inventoryConstants.SET_ADD_BATCH_INVENTORY));
          customToast(toastMessages.PARK_TRADE_SUCCESSFULLY, toastType.SUCCESS);
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.MAKE_TRADE_TRANSACTION,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};
//-------get Sku Number
const getTransactionId = () => {
  return transactionService.getTransactionId().then(
    (response) => {
      if (response) {
        return response;
      }
    },
    (error) => {
      customToast(error, toastType.ERROR);
    }
  );
};

//-------Export Transaction Actions
const emptyDuplicateRecords = () => {
  return (dispatch) => {
    dispatch(success([], transactionConstants.BULK_UPLOAD_HISTORY));
  };
};

//-------addReturn
const sendEmailOrSMS = (data, messageType) => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.SEND_EMAIL_OR_SMS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    transactionService
      .sendEmailOrSms(data)
      .then(
        (response) => {
          if (messageType === EMAIL_MESSAGE_TYPES.USER_REPORTED_ISSUE) {
            dispatch(
              systemActions.pageRedireaction(routesPathConstants.DEFAULT_PATH)
            );
            customToast(toastMessages.PROBLEM_MESSAGE_SENT, toastType.SUCCESS);
          } else {
            customToast(
              toastMessages.RECEIPT_SENT_SUCCESSFULLY,
              toastType.SUCCESS
            );
          }
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.SEND_EMAIL_OR_SMS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};
// temp sku
const generateTempSku = (data) => {
  return (dispatch) => {
    dispatch(success(data, transactionConstants.TEMP_SKU));
  };
};
//-------Delete draft
const deleteDraft = (transactionId, storeDetail, toast) => {
  return (dispatch) => {
    dispatch(
      custom(
        transactionConstants.DELETED_DRAFT,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    transactionService
      .deleteDraft(transactionId)
      .then(
        () => {
          toast &&
            customToast(
              toastMessages.DRAFT_DELETED_SUCCESSFULLY,
              toastType.SUCCESS
            );

          dispatch(
            getAllDraft(
              storeDetail.storeId,
              storeDetail.type,
              storeDetail.startDate,
              storeDetail.endDate
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            transactionConstants.DELETED_DRAFT,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Export Authentication Actions
export const transactionActions = {
  getAllTransaction,
  getAllDraft,
  setTransactionData,
  setNewTradeData,
  toggleAddCustomerModal,
  getGiftCardDetails,
  emptyGiftCardDetails,
  newSaleTransaction,
  cancelCardTransaction,
  newTradeTransaction,
  newTradePark,
  getSearchTransaction,
  emptySearchTransaction,
  addReturn,
  getReturnId,
  parkSale,
  getTransactionId,
  bulkUploadTransactionHistory,
  emptyDuplicateRecords,
  handleNewParkSale,
  sendEmailOrSMS,
  generateTempSku,
  deleteDraft,
  getTradeId,
  setAddTradeBatchInventory,
};
