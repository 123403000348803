import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import BarcodeReader from "react-barcode-reader";
import { useDebouncedCallback } from "use-debounce";
import React, { useEffect, useState, useRef } from "react";

import AddGiftCard from "./AddGiftCard/AddGiftCard";
import AddCustomItem from "./AddCustomItem/AddCustomItem";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import { PRODUCT_TYPES_ENUMS } from "../../../system/globalEnums";
import CustomerImage from "../../../assets/images/customerImage.svg";
import AddNewCustomerModal from "./AddNewCustomer/AddNewCustomerModal";
import SearchField from "../../../shared/components/searchField/SearchField";
import SwitchButton from "../../../shared/components/SwitchButton/SwitchButton";
import FilterDropdowns from "../../../shared/components/FilterDropdowns/FilterDropdowns";
import CustomerDropdown from "../../../shared/components/selectDropdown/CustomerDropdown";
import ProductCartCard from "../../../shared/components/productDetailCard/ProductCartCard";
import SimpleNumberField from "../../../shared/components/inputNumberField/SimpleNumberField";
import ProductDetailCard from "../../../shared/components/productDetailCard/ProductDetailCard";
import {
  customToast,
  parseJsonObject,
  stringifyObject,
  LoadingIndicator,
  getCountOfTotalItems,
  getSumOfPaymentHistory,
  getProductDiscount,
  isArraySame,
  prepareFilterForPaginationCall,
  getDefaultFilterType,
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
  parseToNumber,
  getShortestSku,
  isAllowedDeleteItem,
} from "../../../shared/utility";
import {
  systemActions,
  inventoryActions,
  transactionActions,
} from "../../../redux/actions";
import {
  toastType,
  toastMessages,
  globalConstants,
  buttonNameConstants,
  routesPathConstants,
  buttonTypeConstants,
  transactionConstants,
  transactionDefaultData,
  categoryDropdownConstants,
  defaultGridState,
} from "../../../constants";
import {
  AddIcon,
  CashIcon,
  CrossIcon,
  DeleteIcon,
  SearchIcon,
  BlueBoxIcon,
  ParkSaleIcon,
  RetriveSaleIcon,
  ResetCartIcon,
} from "../../../assets/icons/iconsProvider";
import ConfirmationModal from "../../Inventory/Items/ConfirmationModal/ConfirmationModal";
import InfiniteScroll from "react-infinite-scroll-component";
// import RetriveSaleModal from "./RetriveSaleModal/RetriveSaleModal";

//-------NewTransaction component start here
const NewTransaction = (props) => {
  const {
    customer,
    inventory,
    currentStore,
    tradeDiscount,
    activateSpinner,
    transactionData,
    itemOrganization,
    emptyAllInventory,
    handleNewParkSale,
    deactivateSpinner,
    setTransactionData,
    isAddCustomerModal,
    getPaginatedInventory,
    toggleAddCustomerModal,
  } = props;

  //-----invcentory reducer
  const { allInventory } = inventory;
  const { allDiscount } = tradeDiscount;
  //-------Reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  const cartRef = useRef(null);

  const navigate = useNavigate();
  //-------usestate
  const [customersList, setCustomersList] = useState([]);
  const [searchProductType, setSearchProductType] = useState(
    getDefaultFilterType(customTypes, PRODUCT_TYPES_ENUMS.ALL)
  );
  const [searchProductCategory, setSearchProductCategory] = useState(
    globalConstants.EMPTY_STRING
  );
  const [searchProductSubCategory, setSearchProductSubCategory] = useState("");

  let cartItemsCount = useRef(transactionData?.inventory?.length ?? 0);
  //-------Customer reducer
  const { allCustomers } = customer;
  const [search, setSearch] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [giftCardModal, setGiftCardModal] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [customItemModal, setCustomItemModal] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [selectedCustomerFlag, setSelectedCustomerFlag] = useState(false);
  const [saleProductState, setSaleProductState] = useState(defaultGridState);
  // const [retriveSaleModal, setRetriveSaleModal] = useState(false);

  const handleTransactionData = (data) => {
    setTransactionData(data, currentStore?.id);
  };

  //------Toggle custom item modal
  const toggleCustomItemModal = () => {
    setCustomItemModal(!customItemModal);
  };

  //-------Toggle confirmation modal
  const toggleConfirmationModal = () => {
    setIsConfirmationModal(!isConfirmationModal);
  };

  //-------handle conformation actions
  const handleConfirmYesButtonClick = () => {
    resetTransationPage();
    toggleConfirmationModal();
  };

  const handleConfirmNoButtonClick = () => {
    toggleConfirmationModal();
  };

  //------Toggle custom item modal
  const toggleGiftCardModal = () => {
    setGiftCardModal(!giftCardModal);
  };

  // //------Toggle retrive sale modal
  // const toggleRetriveSaleModal = () => {
  //   setRetriveSaleModal(!retriveSaleModal);
  // };

  //-------handle custom item click
  const handleCustomItemClick = () => {
    toggleCustomItemModal();
  };

  //-------handle custom item click
  const handleGiftCardClick = () => {
    toggleGiftCardModal();
  };

  const updateGridState = (gridState) => {
    setSaleProductState(gridState);
    getPaginatedInventory(currentStore?.id, gridState);
  };

  const fetchMoreData = () => {
    updateGridState({
      ...saleProductState,
      pageSize: Number(saleProductState.pageSize) + 20,
    });
  };

  //-------handle search field change
  const handleSearchFieldChange = (value) => {
    setSearch(value);
    setIsSearchLoading(true);
    handleDebouncedSearch(value);
  };

  //-------add debounce in serach inevntory
  const handleDebouncedSearch = useDebouncedCallback((value) => {
    // searchProduct(value);
    if (value) {
      updateGridState({
        ...saleProductState,
        pageSize: saleProductState.pageSize,
        searchQuery: value,
        filters: prepareFilterForPaginationCall(
          searchProductType,
          searchProductCategory,
          searchProductSubCategory
        ),
      });
    } else {
      setProductsList([]);
    }
  }, 1000);

  //-------handle add customer click
  const handleAddCustomerClick = () => {
    toggleAddCustomerModal(true);
  };

  //-------Add item in cart section
  const addItemInCart = (newItem) => {
    const itemToAdd = { ...newItem, quantity: 1 };

    const existingItem = transactionData.inventory?.find((item) =>
      isArraySame(item.sku, newItem.sku)
    );
    if (existingItem) {
      if (existingItem.quantity >= newItem.quantity) {
        return customToast(
          `Maximum quantity of "${newItem.itemName}" is "${newItem.quantity}".`,
          toastType.ERROR
        );
      } else {
        const updatedItems = transactionData.inventory.map((item) => {
          if (item.sku === existingItem.sku) {
            return { ...item, quantity: item.quantity + 1 };
          }
          return item;
        });
        handleTransactionData({ ...transactionData, inventory: updatedItems });
      }
    } else {
      let discountPercentage = 0;
      let actualPrice = itemToAdd.price;
      let priceAfterDiscount = itemToAdd.price;
      let isDiscountApplied = false;
      const discountedProduct = getProductDiscount(itemToAdd, allDiscount);
      if (discountedProduct) {
        isDiscountApplied = true;
        discountPercentage = discountedProduct.discountPercentage;
        actualPrice = itemToAdd.price;
        priceAfterDiscount =
          actualPrice - actualPrice * (discountPercentage / 100);
      }

      const item = {
        ...itemToAdd,
        actualPrice: actualPrice,
        isDiscountApplied: isDiscountApplied,
        discountPercentage: discountPercentage,
        priceAfterDiscount: parseToDecimalNumber(priceAfterDiscount),
        actualDiscountPercentage: discountPercentage,
        quantity: itemToAdd?.isCustomItem
          ? newItem.quantity
          : itemToAdd.quantity,
      };
      handleTransactionData({
        ...transactionData,
        inventory: [...transactionData?.inventory, item],
      });
    }
  };

  //-------handle product card click
  const handleProductCardClick = (selectedProductCard) => {
    const priceValue = parseToDecimalNumber(selectedProductCard?.price);
    addItemInCart({
      price: priceValue,
      id: selectedProductCard.id,
      sku: selectedProductCard.sku,
      epid: selectedProductCard.epid,
      cogs: selectedProductCard.cogs,
      tags: selectedProductCard.tags,
      item: selectedProductCard.label,
      itemName: selectedProductCard.itemName,
      quantity: selectedProductCard.quantity,
      type: selectedProductCard.type,
      max: Number(selectedProductCard.quantity),
      category: selectedProductCard.category_name,
      productType: selectedProductCard?.productType,
      tcgPlayerUrl: selectedProductCard.tcgPlayerUrl,
      subcategory: selectedProductCard.subcategory,
      total:
        selectedProductCard.price.unit_sell_price *
        selectedProductCard.price.quantity,
      label: `${selectedProductCard.product_id}/${selectedProductCard.category_name}/${selectedProductCard.product_name}/${selectedProductCard.price.type}`,
    });
  };

  //-------delete item from cart section
  const handleDeleteCartItem = (sku) => {
    if (isAllowedDeleteItem(sku, transactionData, currentStore)) {
      customToast(toastMessages.CART_ITEM_DELETE_ERROR, toastType.ERROR);
      return;
    }
    handleTransactionData({
      ...transactionData,
      inventory: transactionData.inventory?.filter((item) => {
        return item.sku !== sku;
      }),
    });
  };

  //-------handle discount Method
  const handleDiscountMethod = (id, value) => {
    handleTransactionData({
      ...transactionData,
      discountedValue: 0,
      discountMethod: value,
    });
  };

  //-------handle upc or sku barcode scanning
  const handleScan = (data) => {
    handleSearchFieldChange(data);
    // searchProduct(data);
    // let searchedItem = allInventory?.find(
    //   (inv) =>
    //     inv.price.quantity > 0 && (inv.sku.includes(data) || inv.upc === data)
    // );
    // if (searchedItem) {
    //   searchedItem = {
    //     ...searchedItem,
    //     id: searchedItem.id,
    //     sku: searchedItem.sku,
    //     epid: searchedItem.epid,
    //     value: searchedItem.product_id,
    //     itemName: searchedItem.product_name,
    //     quantity: searchedItem.price.quantity,
    //     tcgPlayerUrl: searchedItem.tcgPlayerUrl,
    //     category_name: searchedItem.category_name,
    //     price: searchedItem.price.unit_sell_price,
    //     cogs: searchedItem.price.unit_purchase_price,
    //     total: searchedItem.price.unit_sell_price * searchedItem.price.quantity,
    //     label: `${searchedItem.product_id}/${searchedItem.category_name}/${searchedItem.product_name}/${searchedItem.price.type}`,
    //     type: searchedItem.price.type,
    //     subcategory: searchedItem.subcategory,
    //     tags: searchedItem.tags,
    //   };
    //   if (searchedItem) {
    //     handleProductCardClick(searchedItem);
    //   }
    // } else {
    //   customToast(toastMessages.BARCODE_DOESNOT_EXIST, toastType.ERROR);
    // }
  };

  //-------handle qunatity change on cart section
  const handleQuantityChange = (skuNumber, newQuantity) => {
    const updatedCartItems = transactionData.inventory.map((item) =>
      item.sku === skuNumber ? { ...item, quantity: newQuantity } : item
    );
    handleTransactionData({ ...transactionData, inventory: updatedCartItems });
  };

  //-------reset transaction page
  const resetTransationPage = () => {
    setProductsList([]);
    setSearch("");
    handleTransactionData(transactionDefaultData);
  };

  //-------handle scan error
  const handleError = (error) => {};

  const handlePayClick = () => {
    if (transactionData.inventory?.length > 0) {
      // handle component unmounting
      cartItemsCount.current = 0;

      //------- currentAmountToPay
      const currentAmountToPay = parseToDecimalNumber(
        parseToNumber(transactionData.totalAmountToPay) -
          getSumOfPaymentHistory(transactionData.paymentHistory)
      );

      //-------Transaction Data
      const dataToSend = {
        ...transactionData,
        store: currentStore || "",
        currentAmountToPay: currentAmountToPay,
        giftCard: globalConstants.EMPTY_OBJECT,
        paymentMethod: globalConstants.EMPTY_STRING,
      };
      handleTransactionData(dataToSend);
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION_PAYMENT, {
        state: {
          currentStore: currentStore,
        },
      });
    } else {
      customToast(toastMessages.PLEASE_SELECT_SOME_PRODUCT, toastType.ERROR);
    }
  };

  //-------handle discount value change
  const handleDiscountValueChange = (value) => {
    handleTransactionData({
      ...transactionData,
      discountedValue: value,
    });
  };

  //-------handle cart section category discount click
  const handleDiscountChange = (skuNumber) => {
    const updatedCartItems = transactionData.inventory.map((item) =>
      item.sku === skuNumber
        ? {
            ...item,
            isDiscountApplied: !item.isDiscountApplied,
            priceAfterDiscount: !item.isDiscountApplied
              ? parseToDecimalNumber(
                  item.actualPrice -
                    item.actualPrice * (item.actualDiscountPercentage / 100)
                )
              : parseToDecimalNumber(item.actualPrice),
          }
        : item
    );
    handleTransactionData({ ...transactionData, inventory: updatedCartItems });
  };

  //--------handle price change in cart item
  const handlePriceChange = (sku, value) => {
    const inputValue = value;

    if (inputValue < 999999) {
      const updatedCartItems = transactionData.inventory.map((item) =>
        item.sku === sku
          ? {
              ...item,
              isDiscountApplied: !item.isDiscountApplied,
              actualPrice: inputValue,
              priceAfterDiscount: inputValue,
              discountPercentage: 0,
            }
          : item
      );
      handleTransactionData({
        ...transactionData,
        inventory: updatedCartItems,
      });
    }
  };

  const handleParkSaleClick = async () => {
    if (transactionData.inventory?.length > 0) {
      activateSpinner(transactionConstants.MAKE_TRANSACTION);
      const transactionId =
        transactionData?.id || (await transactionActions.getTransactionId());
      deactivateSpinner(transactionConstants.MAKE_TRANSACTION);
      handleNewParkSale(
        { ...transactionData, id: transactionId },
        currentStore
      );
      resetTransationPage();
    } else {
      customToast(toastMessages.PLEASE_SELECT_SOME_PRODUCT, toastType.ERROR);
    }
  };
  const selectedCustomer = (selectedCustomerFlag) => {
    setSelectedCustomerFlag(selectedCustomerFlag);
  };

  // Function to scroll the container to the bottom
  const scrollToBottom = () => {
    if (cartRef.current) {
      cartRef.current.scrollTop = cartRef.current.scrollHeight;
    }
  };

  const handleResetCart = () => {
    if (transactionData?.inventory?.length) {
      toggleConfirmationModal();
    }
  };

  const ScrollLoadingConponent = () => {
    return (
      <div className="w-100 py-2 d-flex justify-content-center align-items-center">
        Loading...
      </div>
    );
  };

  useEffect(() => {
    if (searchProductType?.label && search) {
      updateGridState({
        ...saleProductState,
        filters: prepareFilterForPaginationCall(
          searchProductType,
          searchProductCategory,
          searchProductSubCategory
        ),
      });
    } // eslint-disable-next-line
  }, [searchProductType, searchProductCategory, searchProductSubCategory]);

  useEffect(() => {
    setCustomersList(
      allCustomers?.map((customer) => ({
        value: stringifyObject({
          firstName: customer.firstName,
          lastName: customer.lastName,
          email: customer.email,
          mobile: customer.mobile,
          currentBalance: customer.currentBalance,
          companyCustomerId: customer.companyCustomerId,
          store: {
            id: currentStore.id,
            name: currentStore.storeName,
          },
          id: customer.id,
          name: `${customer.firstName} ${customer.lastName}`,
        }),
        label: (
          <div className="d-flex align-items-center gap-2 ">
            <span>
              <img
                src={CustomerImage}
                className="new-sale-customer-image"
                alt="customer"
              />
            </span>
            {`${customer.firstName} ${customer.lastName}`}
          </div>
        ),
      }))
    );
    // eslint-disable-next-line
  }, [allCustomers]);
  useEffect(() => {
    if (allCustomers && allCustomers.length > 0 && selectedCustomerFlag) {
      handleTransactionData({
        ...transactionData,
        customer: {
          ...allCustomers[0],
          name: allCustomers[0].firstName + " " + allCustomers[0].lastName,
        },
      });
    }
    setSelectedCustomerFlag(false);
    // eslint-disable-next-line
  }, [allCustomers]);

  useEffect(() => {
    let newSubTotal = transactionData.inventory?.reduce((totalPrice, item) => {
      return (
        totalPrice + parseToNumber(item.priceAfterDiscount) * item.quantity
      );
    }, 0);

    let discountAmount = transactionData.discountMethod
      ? newSubTotal * (transactionData.discountedValue / 100)
      : transactionData.discountedValue;

    let amountAfterDiscount = newSubTotal;

    if (transactionData.isDiscountField) {
      amountAfterDiscount = newSubTotal - discountAmount;
    }
    let taxAmount =
      amountAfterDiscount *
      (transactionData.isTaxApplied ? currentStore?.defaultTax / 100 : 0);
    let amountAfterTaxApplied = taxAmount + amountAfterDiscount;

    handleTransactionData({
      ...transactionData,
      tax: taxAmount,
      subTotal: newSubTotal,
      discount: discountAmount,
      totalAmountToPay: parseToDecimalNumber(amountAfterTaxApplied),
      currentAmountToPay: parseToDecimalNumber(
        transactionData.currentAmountToPay > 0
          ? amountAfterTaxApplied -
              getSumOfPaymentHistory(transactionData.paymentHistory)
          : amountAfterTaxApplied
      ),
    });

    // eslint-disable-next-line
  }, [
    transactionData.inventory,
    transactionData.isTaxApplied,
    transactionData.isDiscountField,
    transactionData.discountedValue,
  ]);

  useEffect(() => {
    setAddCustomerModal(isAddCustomerModal);
    // eslint-disable-next-line
  }, [isAddCustomerModal]);

  useEffect(() => {
    if (transactionData?.TransactionType === transactionConstants.PROCESSED) {
      handleTransactionData(transactionDefaultData);
    }
    // handle component unmounting
    cartItemsCount.current = transactionData?.inventory?.length ?? 0;
    // eslint-disable-next-line
  }, [transactionData]);

  const clearSearchText = () => {
    setSearch("");
    setProductsList([]);
  };

  // // handle component unmounting
  // useEffect(() => {
  //   return () => {
  //     if (cartItemsCount.current > 0) {
  //       handleDraftModal(transactionConstants.TRANSACTION_DRAFT_MODAL);
  //     }
  //   }; // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    scrollToBottom();
  }, [transactionData?.inventory?.length]);

  useEffect(() => {
    const filteredtedInventory = allInventory.inventory?.map((option) => ({
      id: option.id,
      upc: option.upc,
      sku: option.sku,
      epid: option.epid,
      tags: option.tags,
      subcategory: option.subcategory,
      value: option.product_id,
      type: option.price.type,
      itemName: option.product_name,
      quantity: option.price.quantity,
      productType: option.productType,
      tcgPlayerUrl: option.tcgPlayerUrl,
      category_name: option.category_name,
      price: option.price.unit_sell_price,
      cogs: option.price.unit_purchase_price,
      total: option.price.unit_sell_price * option.price.quantity,
      label: `${option.product_id}/${option.category_name}/${option.product_name}/${option.price.type}`,
    }));
    if (search) {
      if (
        filteredtedInventory?.length === 1 &&
        filteredtedInventory[0].sku.includes(search)
      ) {
        handleProductCardClick(filteredtedInventory[0]);
        setProductsList([]);
      } else {
        setProductsList(filteredtedInventory);
      }
      setSaleProductState({
        ...saleProductState,
        pageIndex: 1,
        totalRecords: allInventory.totalRecords,
      });
      setIsSearchLoading(false); // eslint-disable-next-line
    }
  }, [allInventory]);

  useEffect(() => {
    return () => {
      emptyAllInventory();
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getSumOfPaymentHistory(transactionData?.paymentHistory) > 0) {
      navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRANSACTION_PAYMENT, {
        state: {
          currentStore: currentStore,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  //------return new transaction component
  return (
    <>
      <div>
        <BarcodeReader onError={handleError} onScan={handleScan} />
      </div>
      <Row className="m-0">
        <Col md={7}>
          <Topbar
            isNewSale={true}
            title="New Transaction"
            sellTradeToggleButton={true}
          />
          <Row className="m-0">
            <Col
              md={12}
              className="d-flex justify-content-between align-items-center "
            >
              <span className="transaction-heading">Add Items</span>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <Button
                  IconImage={AddIcon}
                  handleClick={handleGiftCardClick}
                  label={buttonNameConstants.GIFT_CARD}
                  buttonType={buttonTypeConstants.GHOST_BUTTON}
                />
                <Button
                  IconImage={AddIcon}
                  label={buttonNameConstants.CUSTOM_ITEM}
                  handleClick={handleCustomItemClick}
                />
              </div>
            </Col>
            <Col md={12} className="mt-2">
              <SearchField
                value={search}
                IconImage={SearchIcon}
                BlueBoxIcon={BlueBoxIcon}
                placeHolder="Search Item Name, UPC or SKU"
                onChange={(e) => handleSearchFieldChange(e.target.value)}
                CrossIcon={search ? CrossIcon : ""}
                clearSearchText={clearSearchText}
                LoadingIndicator={isSearchLoading && LoadingIndicator}
              />
            </Col>
            <Col md={10} className="mt-2 d-flex align-items-center gap-2">
              <FilterDropdowns
                customTypes={customTypes}
                customCategories={customCategories}
                customSubCategories={customSubCategories}
                setSearchProductType={setSearchProductType}
                setSearchProductCategory={setSearchProductCategory}
                setSearchProductSubCategory={setSearchProductSubCategory}
                defaultProductType={PRODUCT_TYPES_ENUMS.ALL}
              />
            </Col>
            {productsList?.length > 0 && (
              <Col md={12} className="mt-0">
                <div
                  id="scrollableDiv"
                  className=" searched-products-wrapper container-scrollbar"
                >
                  <InfiniteScroll
                    dataLength={productsList.length}
                    next={fetchMoreData}
                    hasMore={allInventory?.pageSize < allInventory.totalRecords}
                    loader={<ScrollLoadingConponent />}
                    scrollableTarget="scrollableDiv"
                  >
                    {productsList?.map((product, index) => (
                      <ProductDetailCard
                        key={index}
                        price={product.price}
                        productObject={product}
                        skuNumber={product.sku}
                        isNewTransaction={true}
                        productName={product.itemName}
                        productType={product.productType}
                        handleCardClick={handleProductCardClick}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col md={5} className="sale-cart-section px-3 py-3 overflow-hidden">
          <Row className="m-0 px-0 h-100 d-flex flex-column align-items-center">
            <Row className="m-0 px-0">
              <Col
                md={12}
                className="d-flex justify-content-between align-items-center px-0"
              >
                <span className="transaction-heading">Cart</span>
                <div className=" d-flex justify-content-center align-items-center">
                  <Button
                    IconImage={ResetCartIcon}
                    label={buttonNameConstants.RESET}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                    handleClick={handleResetCart}
                  />
                  <Button
                    IconImage={ParkSaleIcon}
                    label={buttonNameConstants.PARK_SALE}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                    handleClick={handleParkSaleClick}
                  />
                  <Button
                    IconImage={RetriveSaleIcon}
                    label={buttonNameConstants.RETRIVE_SALE}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_GHOST}
                    handleClick={() =>
                      navigate(routesPathConstants.SELL_AND_TRADE_DRAFTS)
                    }
                    className="pe-1"
                  />
                </div>
              </Col>
              {transactionData?.customer?.id ? (
                <Col md={12} className="my-2 rounded-2 py-2 bg-white">
                  <div className="w-100 d-flex align-items-center selected-customer">
                    <img src={CustomerImage} alt="customer" />
                    <div className="d-flex flex-column justify-content-center ms-2">
                      <span className="customer-name-heading">{`${
                        transactionData?.customer?.firstName || "Customer"
                      } ${
                        transactionData?.customer?.lastName || "Name"
                      }`}</span>
                      <span>
                        {transactionData?.customer?.mobile ||
                          transactionData?.customer?.email ||
                          "(000) 000-0000"}
                      </span>
                    </div>
                    <div className="d-flex flex-column justify-content-center  align-items-center customer-credit ms-auto">
                      <span>Store Credit</span>
                      <span>
                        $
                        {parseToThousandSeperatorDecimalNumber(
                          transactionData?.customer?.currentBalance || 0
                        )}
                      </span>
                    </div>{" "}
                    {!parseToNumber(
                      transactionData?.paymentHistory?.totalPaidAmount[
                        transactionConstants.STORE_CREDIT
                      ]
                    ) > 0 && (
                      <button
                        type="button"
                        className="cart-card-delete-button"
                        onClick={() => {
                          handleTransactionData({
                            ...transactionData,
                            customer: "",
                          });
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    )}
                  </div>
                </Col>
              ) : (
                <Col md={12} className="mt-1">
                  <CustomerDropdown
                    options={customersList}
                    value={
                      transactionData?.customer?.id
                        ? {
                            value: stringifyObject(transactionData?.customer),
                            label: (
                              <div className="d-flex align-items-center gap-2 ">
                                <span>
                                  <img
                                    src={CustomerImage}
                                    className="new-sale-customer-image"
                                    alt="customer"
                                  />
                                </span>
                                {`${transactionData?.customer.firstName} ${transactionData?.customer.lastName}`}
                              </div>
                            ),
                          }
                        : ""
                    }
                    dropdownIndicator={false}
                    noOptionsMessage="No Customer Found"
                    placeholder="Customer Name, Phone, Email"
                    handleAddCustomerClick={handleAddCustomerClick}
                    SelectIconImage={categoryDropdownConstants.SEARCH_ICON}
                    handleChange={(selectedOption) => {
                      handleTransactionData({
                        ...transactionData,
                        customer: selectedOption?.value
                          ? parseJsonObject(selectedOption?.value)
                          : globalConstants.EMPTY_STRING,
                      });
                    }}
                    selectedCustomerFlag={selectedCustomerFlag}
                  />
                </Col>
              )}
            </Row>
            <Col md={12} className="mt-1 px-0">
              <div
                ref={cartRef}
                className={`cart-cards-wrapper container-scrollbar ${
                  transactionData.isDiscountField &&
                  transactionData.isNoteField &&
                  transactionData?.customer?.id
                    ? "three-fields-open"
                    : (transactionData.isDiscountField &&
                        transactionData.isNoteField) ||
                      (transactionData.isDiscountField &&
                        transactionData?.customer?.id) ||
                      (transactionData.isNoteField &&
                        transactionData?.customer?.id)
                    ? "both-fields-open"
                    : transactionData.isDiscountField ||
                      transactionData.isNoteField ||
                      transactionData?.customer?.id
                    ? "one-field-open"
                    : ""
                }`}
              >
                {transactionData.inventory?.map((cartItem, index) => (
                  <ProductCartCard
                    key={index}
                    max={cartItem.max}
                    isNewTransaction={true}
                    productObject={cartItem}
                    skuNumber={cartItem.sku}
                    quantity={cartItem.quantity}
                    category={cartItem.category}
                    productName={cartItem.itemName}
                    actualPrice={cartItem.actualPrice}
                    handlePriceChange={handlePriceChange}
                    price={cartItem.priceAfterDiscount}
                    handleDeleteCartItem={handleDeleteCartItem}
                    handleQuantityChange={handleQuantityChange}
                    handleDiscountChange={handleDiscountChange}
                    isDiscountApplied={cartItem.isDiscountApplied}
                    isDisableQuantityField={isAllowedDeleteItem(
                      getShortestSku(cartItem.sku),
                      transactionData,
                      currentStore
                    )}
                    discountPercentage={cartItem.discountPercentage}
                  />
                ))}
              </div>
            </Col>
            <Row className="m-0 px-0 mt-auto">
              <Col
                md={12}
                className="d-flex justify-content-between align-items-center mt-2 px-0"
              >
                <span className="opacity-50">ADD</span>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <button
                    type="button"
                    className={`sale-extra-button ${
                      transactionData.isDiscountField && "active-link"
                    }`}
                    onClick={() => {
                      handleTransactionData({
                        ...transactionData,
                        isDiscountField: true,
                      });
                    }}
                  >
                    Discount
                  </button>
                  <button
                    type="button"
                    className={`sale-extra-button ${
                      transactionData.isNoteField && "active-link"
                    }`}
                    onClick={() => {
                      handleTransactionData({
                        ...transactionData,
                        isNoteField: true,
                      });
                    }}
                  >
                    Note
                  </button>
                </div>
              </Col>
              {transactionData.isDiscountField && (
                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center mt-2 px-0"
                >
                  <span>Discount</span>
                  <div className="d-flex justify-content-center align-items-center gap-2 discount-method">
                    $
                    <SwitchButton
                      id="applied-discount-method"
                      onSwitchChange={handleDiscountMethod}
                      isSwitchOn={transactionData.discountMethod}
                    />
                    %
                  </div>
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <SimpleNumberField
                      className="discount-number-field"
                      value={transactionData.discountedValue}
                      onChange={handleDiscountValueChange}
                      maxValue={
                        transactionData.discountMethod
                          ? 100
                          : transactionData?.subTotal
                      }
                    />
                    <button
                      type="button"
                      className="cart-card-delete-button"
                      onClick={() => {
                        handleTransactionData({
                          ...transactionData,
                          isDiscountField: false,
                          discountMethod: false,
                          discountedValue: 0,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </Col>
              )}
              {transactionData.isNoteField && (
                <Col
                  md={12}
                  className="mt-2 d-flex justify-content-between px-0"
                >
                  <textarea
                    value={transactionData.note}
                    onChange={(e) => {
                      handleTransactionData({
                        ...transactionData,
                        note: e.target.value,
                      });
                    }}
                    className="input-textfield text-area-field note-area-field"
                  />
                  <button
                    type="button"
                    className="cart-card-delete-button"
                    onClick={() => {
                      handleTransactionData({
                        ...transactionData,
                        isNoteField: false,
                        note: globalConstants.EMPTY_STRING,
                      });
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </Col>
              )}
              <Col
                md={12}
                className="d-flex justify-content-between align-items-center cart-details-data mt-2 px-0"
              >
                <span>
                  Subtotal ({transactionData.inventory?.length} items)
                </span>
                <span>
                  $
                  {parseToThousandSeperatorDecimalNumber(
                    transactionData.subTotal || 0
                  )}
                </span>
              </Col>
              {transactionData.isDiscountField && (
                <Col
                  md={12}
                  className="d-flex justify-content-between align-items-center cart-details-data mt-1 px-0"
                >
                  <span>Discount</span>
                  <span>
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      transactionData?.discount
                    )}
                  </span>
                </Col>
              )}
              <Col
                md={12}
                className="d-flex justify-content-between align-items-center cart-details-data mt-1 px-0"
              >
                <span className="d-flex align-items-center gap-2">
                  Tax ({currentStore?.defaultTax || 0}%)
                  <input
                    className="checkbox-field"
                    type="checkbox"
                    checked={transactionData.isTaxApplied}
                    onChange={() => {
                      handleTransactionData({
                        ...transactionData,
                        isTaxApplied: !transactionData.isTaxApplied,
                      });
                    }}
                  />
                </span>
                <span>
                  $
                  {parseToThousandSeperatorDecimalNumber(
                    transactionData?.tax || 0
                  )}
                </span>
              </Col>
              <Col md={12} className="px-0">
                <button
                  type="button"
                  className="d-flex justify-content-between align-items-center w-100 mt-3 main-purple-button"
                  onClick={() => handlePayClick()}
                >
                  <div className="d-flex align-items-center gap-1">
                    <CashIcon />
                    <span>Pay</span>
                    <span className="ms-2">
                      {getCountOfTotalItems(
                        transactionData?.inventory || 0,
                        "quantity"
                      )}{" "}
                      items
                    </span>
                  </div>
                  <span>
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      transactionData.totalAmountToPay || 0
                    )}
                  </span>
                </button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
      <AddCustomItem
        customTypes={customTypes}
        addItemInCart={addItemInCart}
        customItemModal={customItemModal}
        customCategories={customCategories}
        customSubCategories={customSubCategories}
        toggleCustomItemModal={toggleCustomItemModal}
      />
      <AddGiftCard
        giftCardModal={giftCardModal}
        addItemInCart={addItemInCart}
        toggleGiftCardModal={toggleGiftCardModal}
      />
      <AddNewCustomerModal
        selectedCustomer={selectedCustomer}
        addCustomerModal={addCustomerModal}
      />
      <ConfirmationModal
        message={toastMessages.RESET_CART_WARNING}
        isConfirmationModal={isConfirmationModal}
        handleNoButtonClick={handleConfirmNoButtonClick}
        handleYesButtonClick={handleConfirmYesButtonClick}
        toggleConfirmationModal={toggleConfirmationModal}
      />
    </>
  );
}; //-------NewTransaction component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  customer: state.customer,
  inventory: state.inventory,
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
  transactionData: state.transaction.transactionData,
  isAddCustomerModal: state.transaction.isAddCustomerModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPaginatedInventory: (storeId, gridState) =>
    dispatch(inventoryActions.getPaginatedInventory(storeId, gridState)),
  setTransactionData: (data, storeId) =>
    dispatch(transactionActions.setTransactionData(data, storeId)),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
  handleNewParkSale: (data, currentStore) =>
    dispatch(transactionActions.handleNewParkSale(data, currentStore)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  handleDraftModal: (isToggle) =>
    dispatch({
      type: transactionConstants.SET_DRAFT_CONFIRMATION,
      data: isToggle,
    }),
  emptyAllInventory: () => dispatch(inventoryActions.emptyAllInventory()),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(NewTransaction);
