import React from "react";
import { Modal, Row, Col } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  buttonTypeConstants,
} from "../../../../constants";

const ConfirmationModal = (props) => {
  const {
    isConfirmationModal,
    handleNoButtonClick,
    handleYesButtonClick,
    toggleConfirmationModal,
    message = "Are you sure you want to delete this item(s)?",
  } = props;

  return (
    <Modal
      size="md"
      animation={true}
      centered
      backdrop="static"
      show={isConfirmationModal}
      onHide={toggleConfirmationModal}
      className={"nested-modal-z-index"}
      backdropClassName={"nested-modal-z-index"}
    >
      <Modal.Body>
        <Row className="m-0 p-0">
          <Col md={12} className="m-0 pe-0">
            <Row className="m-0 p-0 h-100">
              <Col
                md={12}
                className="p-0 mt-2 stolen-card-text d-flex justify-content-center py-4 fs-4 text-start"
              >
                {message}
              </Col>
              <Col md={12} className="mt-auto p-0 d-flex gap-2 ">
                <Button
                  type="button"
                  label={buttonNameConstants.NO}
                  buttonType={buttonTypeConstants.GHOST_BUTTON}
                  className="w-100"
                  handleClick={() => {
                    handleNoButtonClick();
                  }}
                ></Button>
                <Button
                  type="button"
                  className="w-100"
                  handleClick={() => {
                    handleYesButtonClick();
                  }}
                  label={buttonNameConstants.YES}
                ></Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
