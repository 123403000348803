import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import Button from "../../../src/shared/components/Buttons/Button";

import { useNavigate } from "react-router";

import { customerActions } from "../../redux/actions";
import SearchField from "../../../src/shared/components/searchField/SearchField";
import CustomTooltip from "../../../src/shared/components/toolTipComponent/CustomTooltip";
import Topbar from "../../../src/shared/components/topbar/Topbar";
import {
  buttonNameConstants,
  buttonTypeConstants,
  customerConstants,
  inventoryConstants,
  routesPathConstants,
} from "../../constants";
import {
  AddIcon,
  EditIcon,
  CrossIcon,
  DeleteIcon,
  SearchIcon,
  BlueBoxIcon,
  ImportCSVIcon,
  ActionDotsIcons,
  ExportCSVIcon,
} from "../../assets/icons/iconsProvider";
import DataTableComponent from "../../../src/shared/components/table/ReactDataTableComponent";
import { globalConstants } from "../../constants";
import ImportCustomer from "./ImportCustomer/ImportCustomer";
import {
  downloadCSV,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
} from "../../shared/utility";
import DropdownSkeleton from "../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

//-------Customer component start here
const Customer = (props) => {
  const {
    customer,
    currentStore,
    spinnerArray,
    deleteCustomer,
    getAllCustomer,
    bulkUploadCustomers,
    emptyDuplicateRecords,
  } = props;
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [gridState, setGridState] = useState(defaultGridState);
  const navigate = useNavigate();
  let numOfRenders = useRef(0);

  //-------Customer reducer
  const { allCustomers, duplicateCustomers } = customer;
  const [searchText, setSearchText] = useState("");
  const [importCustomerModal, setImportCustomerModal] = useState(false);

  //-------Handle Customer edit click
  const handleEditClick = (row) => {
    navigate(routesPathConstants.CUSTOMERS_EDIT, {
      state: {
        data: row,
        isEdit: true,
        currentStore: currentStore,
      },
    });
  };
  //-------Handle Customer delete click
  const handleDeleteClick = (row) => {
    if (row?.id) {
      deleteCustomer(row.id, currentStore?.companyId, currentStore?.id);
      // reset the pagination
      numOfRenders.current++;
    }
  };

  const toggleImportCustomerModal = () => {
    setImportCustomerModal(!importCustomerModal);
  };

  //-------Handle add button click
  const handleAddClick = () => {
    navigate(routesPathConstants.CUSTOMERS_ADD, {
      state: {
        isEdit: false,
        currentStore: currentStore,
      },
    });
  };
  const columnsHeader = [
    {
      id: "firstName",
      name: "First Name",
      selector: (row) => row.firstName,
      cell: (row) => (
        <span className="users-cell-max-size">{row.firstName}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "lastName",
      name: "Last Name",
      selector: (row) => row.lastName,
      cell: (row) => (
        <span className="users-cell-max-size">{row.lastName}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => <span className="users-cell-max-size">{row.email}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "mobile",
      name: "Mobile",
      selector: (row) => row.mobile,
      cell: (row) => <span className="users-cell-max-size">{row.mobile}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "currentBalance",
      name: "Current Balance",
      selector: (row) => row.currentBalance,
      cell: (row) => (
        <span className="users-cell-max-size">
          ${parseToThousandSeperatorDecimalNumber(row.currentBalance)}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                label={buttonNameConstants.EDIT}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={EditIcon}
                handleClick={() => handleEditClick(row)}
              />
              <Button
                label={buttonNameConstants.DELETE}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={DeleteIcon}
                handleClick={() => handleDeleteClick(row)}
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
    },
  ];
  const [columns, setColumns] = useState([]);

  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };

  //-------useEffects
  useEffect(() => {
    if (currentStore) {
      setColumns(columnsHeader);
      getAllCustomer(currentStore?.companyId, currentStore?.id);
    }
    // eslint-disable-next-line
  }, [currentStore]);

  //-------Handle searchfield change
  const handleFilterData = (e) => {
    const filterList = allCustomers.filter((row) => {
      return (
        String(
          row.currentBalance +
            row.mobile +
            row.firstName +
            row.lastName +
            row.email
        )
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset the pagination
    numOfRenders.current++;
    return filterList;
  };
  const clearSearchText = () => {
    setSearchText("");
  };

  //-------Handle Add button click
  const handleExport = () => {
    const data = searchText ? handleFilterData() : allCustomers;

    downloadCSV(
      data.map((item) => ({
        "First Name": item.firstName,
        "Last Name": item.lastName,
        Email: item.email,
        Mobile: item.mobile,
        "Drivers License": item.driverLicense,
        "Street 1": item.line1,
        "Street 2": item.line2,
        City: item.city,
        State: item.state,
        Zip: item.zip,
        "Current Balance": item.currentBalance,
      })),
      inventoryConstants.CUSTOMER_EXPORT_FILE_NAME
    );
  };

  //-------Return Customers Component
  return (
    <>
      <Topbar
        countChip={true}
        totalCustomers={allCustomers?.length}
        title="Customers"
      ></Topbar>
      {isSpinnerEnabled(spinnerArray, customerConstants.GET_ALL_CUSTOMERS) ? (
        <Row className="m-0 w-100 user-screen-wrapper">
          <Col md={5}>
            <DropdownSkeleton width="100%" />
          </Col>
          <Col md={3}></Col>
          <Col
            md={4}
            className="d-flex justify-content-end align-items-center gap-3"
          >
            <DropdownSkeleton />
            <DropdownSkeleton />
            <DropdownSkeleton />
          </Col>
        </Row>
      ) : (
        <Row className="m-0 w-100 user-screen-wrapper">
          <Col md={5}>
            <SearchField
              onChange={(e) => setSearchText(e.target.value)}
              IconImage={SearchIcon}
              placeHolder="Search"
              value={searchText}
              CrossIcon={searchText ? CrossIcon : ""}
              clearSearchText={clearSearchText}
              BlueBoxIcon={BlueBoxIcon}
            />
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="d-flex justify-content-end align-items-center gap-3"
          >
            <Button
              label={buttonNameConstants.EXPORT_CUSTOMER}
              IconImage={ExportCSVIcon}
              className="w-20"
              buttonType={buttonTypeConstants.LIGHT_PURPLE}
              handleClick={() => handleExport()}
            />
            <Button
              label={buttonNameConstants.IMPORT_CUSTOMER}
              IconImage={ImportCSVIcon}
              className="w-20"
              buttonType={buttonTypeConstants.LIGHT_PURPLE}
              handleClick={() => toggleImportCustomerModal()}
            />
            <Button
              label={buttonNameConstants.ADD}
              IconImage={AddIcon}
              className="w-20"
              handleClick={handleAddClick}
            />
          </Col>
        </Row>
      )}
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3">
        <DataTableComponent
          className={"customer-table-height"}
          key={numOfRenders.current}
          columns={columns}
          data={searchText ? handleFilterData() : allCustomers}
          onSort={onTableSort}
          defaultSortFieldId={defaultSortFieldId}
          gridState={gridState}
          setGridState={updateGridState}
          total={searchText ? handleFilterData()?.length : allCustomers?.length}
        />
      </Row>
      <ImportCustomer
        currentStore={currentStore}
        duplicateCustomers={duplicateCustomers}
        importCustomerModal={importCustomerModal}
        bulkUploadCustomers={bulkUploadCustomers}
        emptyDuplicateRecords={emptyDuplicateRecords}
        toggleImportCustomerModal={toggleImportCustomerModal}
      />
    </>
  );
}; //-------Customers component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  customer: state.customer,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  deleteCustomer: (id, companyId, storeId) => {
    dispatch(customerActions.deleteCustomer(id, companyId, storeId));
  },
  bulkUploadCustomers: (companyId, storeId, importCustomerMisc) =>
    dispatch(
      customerActions.bulkUploadCustomers(
        companyId,
        storeId,
        importCustomerMisc
      )
    ),
  emptyDuplicateRecords: () =>
    dispatch(customerActions.emptyDuplicateRecords()),
  getAllCustomer: (companyId, storeId) =>
    dispatch(customerActions.getAllCustomer(companyId, storeId)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(Customer);
